import React from 'react';
import { useTranslation } from '@blendin/sdk-react';

export const ProjectTranslationJobShowScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-translation-job-show-screen'>
      <div className='content-container-wide'>
        <h2 className='project-translation-job-title'>{t('Project Translation Job Show Screen')}</h2>
      </div>
    </div>
  )
}
