import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@blendin/sdk-react';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { Spinner } from '../components/Spinner';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { UserSettings } from '../types/user';
import { SubscriptionTier } from '../types/subscription_tier';
import { APIClient } from '../services/APIService';
import { numberToCurrency } from '../helpers/strings';
import { useModal } from '../hooks/useModal';
import { ModalType } from '../types/modal';
import checkmark from '../assets/checkmark.svg';
import remove from '../assets/remove.svg';

const SubscriptionTierFeatures: React.FC<{subscriptionTier: SubscriptionTier, billingInterval: string}> = ({subscriptionTier, billingInterval}) => {
  const { t } = useTranslation();
  const [selectedProUsageIndex, setSelectedProUsageIndex] = useState<number>(0);
  const [selectedEliteUsageIndex, setSelectedEliteUsageIndex] = useState<number>(0);

  if (subscriptionTier.name === 'starter') {
    return (
      <>
        <h3 className='subscription-tier-name'>{subscriptionTier.name.toLocaleUpperCase()}</h3>
        <hr />
        <p className='subscription-tier-base-price'>
          $<span className='amount'>{numberToCurrency(subscriptionTier.base_price)}</span> / month
        </p>
        <p className='subscription-tier-billing-interval'>{t('No commitment')}</p>
        <p className='usage-pricing'>{t('Up to 2,500 words')}</p>
        <hr />
        <div className='subscription-tier-features'>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Up to 2 locales')}</p>
          </div>
          <div className='tier-feature disabled'>
            <img src={remove} className='icon-disabled' />
            <p className='tier-feature-text'>{t('Multiple users')}</p>
          </div>
          <div className='tier-feature disabled'>
            <img src={remove} className='icon-disabled' />
            <p className='tier-feature-text'>{t('Human QA check')}</p>
          </div>
          <div className='tier-feature disabled'>
            <img src={remove} className='icon-disabled' />
            <p className='tier-feature-text'>{t('Premium support')}</p>
          </div>
        </div>
      </>
    )
  }

  if (subscriptionTier.name === 'pro') {
    return (
      <>
        <h3 className='subscription-tier-name'>{subscriptionTier.name.toLocaleUpperCase()}</h3>
        <hr />
        <p className='subscription-tier-base-price'>
          $<span className='amount'>{numberToCurrency(subscriptionTier.base_price + subscriptionTier.usage_pricing[selectedProUsageIndex][1])}</span> / month
        </p>
        <p className='subscription-tier-billing-interval'>{t('Billed {{billingInterval}}', { billingInterval: billingInterval.toLocaleLowerCase() })}</p>
        <select
          className='usage-pricing-select'
          value={selectedProUsageIndex}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectedProUsageIndex(Number(e.target.value)) }}>
          {subscriptionTier.usage_pricing.map((usage: [number, number], index: number) => (
            <option
              key={index}
              value={index}>
              {t('Up to {{numberOfWords}} words', {numberOfWords: usage[0].toLocaleString('en-US')})}
            </option>
          ))}
          <option
            value={subscriptionTier.usage_pricing.length}>
            {t('More than {{numberOfWords}} words', { numberOfWords: subscriptionTier.usage_pricing[subscriptionTier.usage_pricing.length - 1][0].toLocaleString('en-US') })}
          </option>
        </select>
        <hr />
        <div className='subscription-tier-features'>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Unlimited locales')}</p>
          </div>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Unlimited users')}</p>
          </div>
          <div className='tier-feature disabled'>
            <img src={remove} className='icon-disabled' />
            <p className='tier-feature-text'>{t('Human QA check')}</p>
          </div>
          <div className='tier-feature disabled'>
            <img src={remove} className='icon-disabled' />
            <p className='tier-feature-text'>{t('Premium support')}</p>
          </div>
        </div>
      </>
    )
  }

  if (subscriptionTier.name === 'elite') {
    return (
      <>
        <h3 className='subscription-tier-name'>{subscriptionTier.name.toLocaleUpperCase()}</h3>
        <hr />
        <p className='subscription-tier-base-price'>
          $<span className='amount'>{numberToCurrency(subscriptionTier.base_price + subscriptionTier.usage_pricing[selectedEliteUsageIndex][1])}</span> / month
        </p>
        <p className='subscription-tier-billing-interval'>{t('Billed {{billingInterval}}', { billingInterval: billingInterval.toLocaleLowerCase() })}</p>
        <select
          className='usage-pricing-select'
          value={selectedEliteUsageIndex}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setSelectedEliteUsageIndex(Number(e.target.value)) }}>
          {subscriptionTier.usage_pricing.map((usage: [number, number], index: number) => (
            <option
              key={index}
              value={index}>
              {t('Up to {{numberOfWords}} words', {numberOfWords: usage[0].toLocaleString('en-US')})}
            </option>
          ))}
          <option
            value={subscriptionTier.usage_pricing.length}>
            {t('More than {{numberOfWords}} words', { numberOfWords: subscriptionTier.usage_pricing[subscriptionTier.usage_pricing.length - 1][0].toLocaleString('en-US') })}
          </option>
        </select>
        <hr />
        <div className='subscription-tier-features'>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Unlimited locales')}</p>
          </div>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Unlimited users')}</p>
          </div>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Human QA check')}</p>
          </div>
          <div className='tier-feature enabled'>
            <img src={checkmark} className='icon-enabled' />
            <p className='tier-feature-text'>{t('Premium support')}</p>
          </div>
        </div>
      </>
    )
  }

  return (<></>);
}

export const PricingScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveModal } = useModal();
  const [subscriptionTiers, setSubscriptionTiers] = useState<SubscriptionTier[]>([]);
  const [user, setUser] = useState<UserSettings | undefined>(undefined);
  const [billingInterval, setBillingInterval] = useState<string>('Monthly');

  useEffect(() => {
    (async () => {
      const subscriptionTiersResponse = await APIClient.get(API_ROUTES.SubscriptionTiersIndex);

      if (subscriptionTiersResponse.status === 200) {
        setSubscriptionTiers(subscriptionTiersResponse.data.subscription_tiers);
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
      const userResponse = await APIClient.authenticatedGet(API_ROUTES.UserSettings);

      if (userResponse.status === 200) {
        setUser(userResponse.data.user as UserSettings);
      }
    })();
  }, []);

  const handleSelectPlan = (subscriptionTier: SubscriptionTier) => {
    if (!user) {
      navigate(UI_ROUTES.Login);
    } else if (user.has_active_card) {
      setActiveModal(ModalType.ChangeSubscriptionTier, {
        subscriptionTier,
        userHasActiveCard: true
      });
    } else {
      setActiveModal(ModalType.ChangeSubscriptionTier, {
        subscriptionTier,
        userHasActiveCard: false
      });
    }
  }

  return (
    <div className='Screen pricing-screen'>
      {subscriptionTiers.length > 0 ? (
        <div className='Section pricing-section'>
          <div className='pricing-header'>
            <h2 className='pricing-title'>{t('Choose Your Plan')}</h2>
            <div className='flex-spacer' />
            <ToggleSwitch options={['Monthly', 'Yearly']} selectedOption={billingInterval} setSelectedOption={setBillingInterval} />
          </div>
          <div className='pricing-options'>
            <div className='subscription-tiers'>
              {subscriptionTiers.map((subscriptionTier, index) => (
                <div
                  key={index}
                  className='subscription-tier'>
                  <SubscriptionTierFeatures subscriptionTier={subscriptionTier} billingInterval={billingInterval} />
                  {user && user.subscription_tier.uuid === subscriptionTier.uuid ? (
                    <div className='active-subscription-tier select-plan'>
                      <p className='active-subscription-tier-text'>{t('Active Plan')}</p>
                    </div>
                  ) : null}
                  {user && user.subscription_tier.uuid !== subscriptionTier.uuid ? (
                    <div
                      className='action-button primary select-plan'
                      onClick={() => handleSelectPlan(subscriptionTier)}>
                      <p className='action-button-text'>{t('Select this plan')}</p>
                    </div>
                  ) : null}
                  {!user ? (
                    <>
                      <div
                        className={`action-button ${subscriptionTier.name === 'pro' ? 'primary' : 'tertiary'} select-plan get-started`}
                        onClick={() => handleSelectPlan(subscriptionTier)}>
                        <p className='action-button-text'>{t('Get Started for Free')}</p>
                      </div>
                      <p className='no-credit-card-required'>
                        {t('No credit card required')}
                      </p>
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Spinner spinnerType='Chase' />
      )}
    </div>
  )
}
