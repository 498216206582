import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GITHUB_OAUTH_URL, GOOGLE_OAUTH_SIGNUP_URL } from '../constants';
import { useTranslation } from '@blendin/sdk-react';
import { APIClient } from '../services/APIService';
import { AxiosError } from 'axios';
import { API_ROUTES, UI_ROUTES } from '../helpers/routes';
import { StorageKeys } from '../types/storage';
import { useStorage } from '../hooks/useStorage';
import { useFlashMessage } from '../hooks/useFlashMessage';
import { useKeyPress } from '../hooks/useKeyPress';
import googleIcon from '../assets/images/google-icon.svg';
import githubIcon from '../assets/images/github-icon.svg';
import gitlabIcon from '../assets/images/gitlab-icon.svg';

import posthog from 'posthog-js';

export const SignupScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [userSession, setUserSession] = useStorage(StorageKeys.UserSession);
  const [currentUser, setCurrentUser] = useStorage(StorageKeys.CurrentUser);

  useKeyPress("Enter", () => {
    createAccountWithEmail();
  })

  const handleGoogleSignup = () => {
    window.location.href = GOOGLE_OAUTH_SIGNUP_URL;
  }

  const createAccountWithEmail = async () => {
    try {
      const userResponse = await APIClient.post(API_ROUTES.UserCreate, {
        email: email,
        password: password
      })
      if (userResponse.status === 200) {
        posthog.identify(userResponse.data.user_uuid, {
          email: userResponse.data.user_email,
          role: userResponse.data.user_role
        });
        setUserSession(userResponse.data.jwt);
        setCurrentUser({
          uuid: userResponse.data.user_uuid,
          email: userResponse.data.user_email,
          role: userResponse.data.user_role
        })
        if (userResponse.data.role === 'translator') {
          navigate(UI_ROUTES.TranslatorDashboard);
        } else {
          navigate(UI_ROUTES.UserDashboard);
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 409) {
        setFlashMessage({ message: t('User exists for email'), type: 'error' });
        navigate(UI_ROUTES.Signup);
      }
    }
  }

  return (
    <div className='Screen signup-screen'>
      {/* <div className='signup-section signup-copy-section'>
        <h2>{t('Why should I sign up?')}</h2>
        <p>{t('Every translation job on Blendin takes place in the context of a project. Signing up and creating a project is completely free!')}</p>
      </div> */}
      <div className='signup-section signup-form-section'>
        <div className='email-signup'>
          <div className="form-field labeled-input">
            <p className="input-label">
              {t('Email')}
            </p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className='form-field labeled-input'>
            <p className="input-label">{t('Password')}</p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div
            className='action-button primary'
            onClick={createAccountWithEmail}>
            <p className='action-button-text'>{t({text: 'Create Account', context: 'command'})}</p>
          </div>
        </div>
        <div className="oauth-divider">
          <div className="divider fade-out flex-spacer" />
          <p className="divider-text">{t('or continue with:')}</p>
          <div className="divider fade-in flex-spacer" />
        </div>
        <div className='oauth-signups'>
          <div
            className='oauth-signup oauth-signup-google'
            onClick={handleGoogleSignup}>
            <div className='oauth-signup-option'>
              <img src={googleIcon} />
              <p>Google</p>
            </div>
          </div>
          {/* <a
            href={GITHUB_OAUTH_URL}
            className='oauth-signup oauth-signup-github'>
            <div className='oauth-signup-option'>
              <img src={githubIcon} />
              <p>Github</p>
            </div>
          </a>
          <div className='oauth-signup oauth-signup-gitlab'>
            <div className='oauth-signup-option'>
              <img src={gitlabIcon} />
              <p>Gitlab</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
