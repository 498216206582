import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayFlag } from '@blendin/sdk-js';
import { useTranslation } from '@blendin/sdk-react';
import { APIClient } from '../services/APIService';
import { Locale } from '../types/locale';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { Checkbox, Radio } from 'pretty-checkbox-react';
import { AxiosError } from 'axios';
import { useFlashMessage } from '../hooks/useFlashMessage';
import '@djthoms/pretty-checkbox';

export const ProjectCreateScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const [projectName, setProjectName] = useState<string>('');
  const [locales, setLocales] = useState<Locale[]>([]);
  const [selectedSourceLocale, setSelectedSourceLocale] = useState<string>('en');
  const [selectedTargetLocales, setSelectedTargetLocales] = useState<string[]>([]);
  const [selectedPrivacyMode, setSelectedPrivacyMode] = useState<'private' | 'public'>('private');

  useEffect(() => {
    (async () => {
      const localesResponse = await APIClient.get(API_ROUTES.LocalesIndex, {})
      if (localesResponse.status === 200) {
        setLocales(localesResponse.data.locales);
      }
    })();
  }, []);

  const handleTargetLocaleSelect = (nextValue: boolean, iso: string) => {
    if (nextValue) {
      setSelectedTargetLocales([...selectedTargetLocales, iso]);
    } else {
      setSelectedTargetLocales(selectedTargetLocales.filter(value => value !== iso));
    }
  }

  const createProject = async () => {

    try {
      const projectsResponse = await APIClient.authenticatedPost(API_ROUTES.ProjectCreate, {
        project_name: projectName,
        privacy_mode: selectedPrivacyMode,
        source_locale_iso: selectedSourceLocale,
        target_locale_isos: selectedTargetLocales
      });

      if (projectsResponse.status === 200) {
        navigate(UI_ROUTES.ProjectDashboard.replace(':uuid', projectsResponse.data.project.uuid));
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 422) {
        // @ts-ignore
        setFlashMessage({ message: axiosError.response.data.message, type: 'error' });
      }
     }
  }

  return (
    <div className='Screen project-create-screen'>
      <div className='content-container'>
        <h2 className='page-title'>{t('Create Project')}</h2>
        <div className='form-section'>
          <div className='form-field labeled-input project-name'>
            <p className='input-label'>{t('Project Name')}</p>
            <input
              type="text"
              placeholder={t('Usually the name of your website or app')}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)} />
          </div>
          <div className='privacy-mode'>
            <Radio
              onClick={() => setSelectedPrivacyMode('private')}
              name='privacy-mode'
              className='privacy-mode-option'
              value='private'
              state={selectedPrivacyMode}>
              {t('Private project')}
            </Radio>
            <Radio
              onClick={() => setSelectedPrivacyMode('public')}
              name='privacy-mode'
              className='privacy-mode-option'
              value='public'
              state={selectedPrivacyMode}>
              {t('Public project')}
            </Radio>
          </div>
          <div className='form-field labeled-input source-locale'>
            <p className='input-label'>{t('Source language')}</p>
            <select
              className='source-locale-select'
              value={selectedSourceLocale}
              onChange={(e) => setSelectedSourceLocale(e.target.value)}>
              {locales.map((locale, index) => (
                <option key={index} value={locale.iso}>
                  {locale.name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-field labeled-input target-locales'>
            <p className='input-label'>{t('Target languages')}</p>
            <div className='target-locales-select'>
              <div className='select-locales'>
                {locales.map((locale, index) => (
                  <div
                    key={index}
                    className='target-locale-select'
                    onClick={() => {
                      const nextValue = !selectedTargetLocales.includes(locale.iso);
                      handleTargetLocaleSelect(nextValue, locale.iso);
                    }}>
                    <Checkbox
                      readOnly={true}
                      className='checkbox-wrapper'
                      checked={selectedTargetLocales.includes(locale.iso)} />
                    {displayFlag(locale.iso) ? (
                      <img className='locale-flag' src={`https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/${displayFlag(locale.iso).toLowerCase()}.svg`} />
                    ) : null}
                    <p className=''>{locale.name}</p>
                  </div>
                ))}
              </div>
              <div className='selected-locales'>
                {selectedTargetLocales.map((iso, index) => (
                  <div
                    key={index}
                    className='selected-locale'>
                    {displayFlag(iso) ? (
                      <img className='locale-flag' src={`https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/${displayFlag(iso).toLowerCase()}.svg`} />
                    ) : null}
                    <p
                      key={index}
                      className='selected-locale-text'>{locales.find((locale) => locale.iso === iso)!.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='action-buttons'>
            <div className='flex-spacer' />
            <div
              className='action-button primary'
              onClick={createProject}>
              <p className='action-button-text'>{t({text: 'Create Project', context: 'command'})}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
