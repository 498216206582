import React, { useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { useModal } from '../../hooks/useModal';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ModalType } from '../../types/modal';
import { APIClient } from '../../services/APIService';
import { UI_ROUTES, API_ROUTES } from '../../helpers/routes';
import { redirect } from 'react-router-dom';

export const UpdatePaymentMethodModal: React.FC = () => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState<string | undefined>(undefined);

  const closeModalIfElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setActiveModal(ModalType.None);
    }
  }

  const handleUpdatePaymentMethod = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {}
      })

      if (error) {
        setError(error.message);
      } else if (paymentMethod) {
        const apiResponse = await APIClient.authenticatedPost(API_ROUTES.StripeAddPaymentMethod, {
          payment_method: paymentMethod
        })

        if ([200, 201].includes(apiResponse.status)) {
          setActiveModal(ModalType.None);
          redirect(UI_ROUTES.UserSettings);
        }
      }
    }
  }

  return (
    <div
      className='Modal'
      onClick={closeModalIfElementClick}>
      <div className='modal-content'>
        <div className='modal-title-container'>
          <h3 className='modal-title'>{t('Update payment method')}</h3>
          <div className='flex-spacer' />
          <p
            className='modal-close'
            onClick={closeModalIfElementClick}>
            X
          </p>
        </div>
        <div className='modal-body'>
          <CardElement className='card-details' />
          {error && <div>{error}</div>}
        </div>
        <div className='modal-actions'>
          <div
            className='action-button tertiary'
            onClick={() => setActiveModal(ModalType.None)}>
            <p className='action-button-text'>{t({text: 'Close', context: 'command'})}</p>
          </div>
          <div
            className='action-button primary'
            onClick={handleUpdatePaymentMethod}>
            <p className='action-button-text'>{t({text: 'Submit', context: 'command'})}</p>
          </div>
        </div>
      </div>
    </div>
  )

}
