import React, { useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { useModal } from '../../hooks/useModal';
import { ModalType } from '../../types/modal';
import { APIClient } from '../../services/APIService';
import { API_ROUTES } from '../../helpers/routes';
import { Checkbox } from 'pretty-checkbox-react';
import { Spinner } from '../../components/Spinner';

export const CreateApiTokenModal: React.FC = () => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiTokenName, setApiTokenName] = useState<string>('');
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [apiTokenValue, setApiTokenValue] = useState<string | undefined>(undefined);

  const closeModalIfElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setActiveModal(ModalType.None);
    }
  }

  const handleTargetScopeSelect = (nextValue: boolean, name: string) => {
    if (nextValue) {
      setSelectedScopes([...selectedScopes, name]);
    } else {
      setSelectedScopes(selectedScopes.filter(value => value !== name));
    }
  }

  const handleCreateToken = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsLoading(true);
    const apiResponse = await APIClient.authenticatedPost(API_ROUTES.UserCreateApiToken, {
      api_token: {
        name: apiTokenName
      }
    })
    if ([201].includes(apiResponse.status)) {
      setApiTokenValue(apiResponse.data.api_token_value);
      setIsLoading(false);
    }
  }

  return (
    <div
      className='Modal create-api-token'
      onClick={closeModalIfElementClick}>
      <div className='modal-content'>
        <div className='modal-title-container'>
          <h3 className='modal-title'>{t('New Api Token')}</h3>
          <div className='flex-spacer' />
          <p
            className='modal-close'
            onClick={closeModalIfElementClick}>
            X
          </p>
        </div>
        <div className='modal-body'>
          {isLoading ? (
            <Spinner spinnerType='Chase' />
          ) : (
            <>
              {apiTokenValue ? (
                <>
                  <div className='labeled-input'>
                    <p className="input-label">{t('Access Token')}</p>
                    <input
                      type="text"
                      value={apiTokenValue}
                      disabled={true} />
                  </div>
                </>
              ) : (
                <>
                  <div className='labeled-input'>
                    <p className="input-label">{t('Token Name')}</p>
                    <input
                      type="text"
                      value={apiTokenName}
                      onChange={(e) => setApiTokenName(e.target.value)} />
                  </div>
                  <div className='scopes'>
                    <p className='input-label'>{t('Scopes')}</p>
                    <div
                      className='checkbox-select'
                      onClick={() => {
                        const nextValue = !selectedScopes.includes('upload');
                        handleTargetScopeSelect(nextValue, 'upload');
                      }}>
                      <Checkbox
                        readOnly={true}
                        className='checkbox-wrapper'
                        checked={selectedScopes.includes('upload')} />
                      <p className='checkbox-label'>{t({text: 'Upload', context: 'command'})}</p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className='modal-actions'>
          {isLoading ? null : (
            <>
              {apiTokenValue ? (
                <div
                  className='action-button tertiary'
                  onClick={() => setActiveModal(ModalType.None)}>
                  <p className='action-button-text'>{t({text: 'Close', context: 'command'})}</p>
                </div>
              ) : (
                <>
                  <div
                    className='action-button tertiary'
                    onClick={() => setActiveModal(ModalType.None)}>
                    <p className='action-button-text'>{t({text: 'Close', context: 'command'})}</p>
                  </div>
                  <div
                    className='action-button primary'
                    onClick={handleCreateToken}>
                    <p className='action-button-text'>{t({text: 'Create', context: 'command'})}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )

}
