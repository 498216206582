import React, { useEffect, useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { User } from '../types/user';
import { LocaleTranslatorDashboard } from '../types/locale';
import { APIClient } from '../services/APIService';
import { useNavigate } from 'react-router-dom';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { DataTable } from '../components/DataTable';
import { Spinner } from '../components/Spinner';

export const TranslatorDashboardScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [locales, setLocales] = useState<LocaleTranslatorDashboard[]>([]);

  useEffect(() => {
    (async () => {

      const userResponse = await APIClient.authenticatedGet(API_ROUTES.TranslatorDashboard);

      if (userResponse.status === 200) {
        setUser(userResponse.data.user as User);
        setLocales(userResponse.data.unconfirmed_translations as LocaleTranslatorDashboard[]);
      } else if (userResponse.status === 401) {
        navigate(UI_ROUTES.Login);
      }

    })();
  }, []);

  return (
    <div className='translator-dashboard-screen'>
      <div className='content-container'>
        {user ? (
          <>
            <h2 className='confirm-translations'>{t('Verify translations')}</h2>
            <DataTable
              className='unverified-translations-table'
              headerRow={[t('Locale'), t('Unverified translations')]}
              itemsPerPage={15}
              dataRows={
                locales.map((locale, index) => (
                  {
                  onClickRow: () => navigate(UI_ROUTES.TranslatorConfirmTranslations.replace(':iso', locale.iso)),
                  items: [
                    locale.name,
                    `${locale.unconfirmed_translation_count}`
                  ]
                }))
              } />
          </>
        ) : (
          <Spinner spinnerType='Chase' />
        )}
      </div>
    </div>
  )
}
