import { User } from './user';

export enum StorageKeys {
  CurrentUser = 'current_user',
  UserSession = 'user_session'
}

export interface StorageValue {
  [StorageKeys.UserSession]: string;
  [StorageKeys.CurrentUser]: User;
}

export enum StorageLocation {
  Local = 'local',
  Secure = 'secure',
  Session = 'session'
}

export const STORAGE_VALUE_LOCATIONS: Record<StorageKeys, StorageLocation> = {
  [StorageKeys.CurrentUser]: StorageLocation.Local,
  [StorageKeys.UserSession]: StorageLocation.Local
}
