export const truncate = (input: string, maxLength: number): string => {
  if (!input) { return ``; }
  if (input.length <= maxLength) {
    return input;
  }
  return `${input.substring(0, maxLength - 3)}...`;
}

export const numberToCurrency = (input: number): string => {
  return `${input / 100}`;
}
