import React, { useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { ModalType } from '../../types/modal';
import { useModal } from '../../hooks/useModal';
import { SubscriptionTier } from '../../types/subscription_tier';
import { numberToCurrency } from '../../helpers/strings';
import { APIClient } from '../../services/APIService';
import { UI_ROUTES, API_ROUTES } from '../../helpers/routes';
import { useNavigate } from 'react-router-dom';
import { useFlashMessage } from '../../hooks/useFlashMessage';

interface ModalProps {
  subscriptionTier: SubscriptionTier;
  userHasActiveCard: boolean
}

export const ChangeSubscriptionTierModal: React.FC<ModalProps> = ({
  subscriptionTier,
  userHasActiveCard
}) => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();

  const closeModalIfElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setActiveModal(ModalType.None);
    }
  }

  const confirmSubscriptionTier = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const apiResponse = await APIClient.authenticatedPost(API_ROUTES.UserSubscriptionTierUpdate, {
      subscription_tier_uuid: subscriptionTier.uuid
    })

    if ([200].includes(apiResponse.status)) {
      setActiveModal(ModalType.None);
      setFlashMessage({ message: t('Successfully updated subscription'), type: "success" })
      navigate(UI_ROUTES.UserDashboard);
    }
  }

  return (
    <div
      className='Modal change-subscription-tier'
      onClick={closeModalIfElementClick}>
      <div className='modal-content'>
        <div className='modal-title-container'>
          <h3 className='modal-title'>{t('Select Plan')}</h3>
          <div className='flex-spacer' />
          <p
            className='modal-close'
            onClick={closeModalIfElementClick}>
            X
          </p>
        </div>
        <div className='modal-body'>
          <h3 className='subscription-tier-name'>{subscriptionTier.name}</h3>
          <p className='subscription-tier-base-price'>
            {t('{{basePrice}} / month', {
              basePrice: numberToCurrency(subscriptionTier.base_price)
            })}
          </p>
        </div>
        <div className='modal-actions'>
          <div
            className='action-button tertiary'
            onClick={() => setActiveModal(ModalType.None)}>
            <p className='action-button-text'>{t({text: 'Close', context: 'command'})}</p>
          </div>
          <div
            className='action-button primary'
            onClick={confirmSubscriptionTier}>
            <p className='action-button-text'>{t({text: 'Subscribe', context: 'command'})}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
