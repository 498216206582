import React, { useEffect, useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { UserSettings } from '../types/user';
import { APIClient } from '../services/APIService';
import { useNavigate } from 'react-router-dom';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { ApiTokenIndex } from '../types/api_tokens';
import { DataTable } from '../components/DataTable';
import { Spinner } from '../components/Spinner';
import { useModal } from '../hooks/useModal';
import { ModalType } from '../types/modal';
import moment from 'moment';

export const UserSettingsScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveModal } = useModal();

  const [user, setUser] = useState<UserSettings | undefined>(undefined);

  useEffect(() => {
    (async () => {

      const userResponse = await APIClient.authenticatedGet(API_ROUTES.UserSettings);

      if (userResponse.status === 200) {
        setUser(userResponse.data.user as UserSettings);
      } else if (userResponse.status === 401) {
        navigate(UI_ROUTES.Login);
      }

    })();
  }, [navigate]);

  return (
    <div className='user-settings-screen'>
      <div className='content-container'>
        <h2 className='user-settings-title'>{t('Account Settings')}</h2>
        {user ? (
          <>
            <h4 className='api-tokens-title'>{t('API Tokens')}</h4>
            <p className='api-tokens-description'>{t('Create personal access tokens for authorization when working with Blendin API, CLI, some of the integrations and plugins.')}</p>
            <div
              className='action-button tertiary new-token'
              onClick={() => setActiveModal(ModalType.CreateApiToken)}>
              <p className='action-button-text'>{t('New Token')}</p>
            </div>
            <DataTable
              className='api-tokens'
              headerRow={[t('Token Name'), t('Created'), t('Status'), t('Actions')]}
              dataRows={
                user.api_tokens.map((apiToken: ApiTokenIndex, index) => ({
                  items: [
                    apiToken.name,
                    moment.unix(apiToken.created_at).fromNow(),
                    apiToken.token_state,
                    t({text: 'Revoke', context: 'command'})
                  ]
                }))
              }
            />
            <h4 className='oauth-integrations-title'>{t('OAuth Integrations')}</h4>
            <p className='oauth-integrations-description'>{t('Manage the accounts you have linked to your Blendin account. These connections allow you to log in to Blendin with a single click.')}</p>
            <h4 className='subscription-title'>{t('Subscription')}</h4>
            <p>{t('Your subscription: {{subscription}}', { subscription: user.subscription_tier.name })}</p>
            <p>
              {t('{{userLocaleTokens}} out of {{subscriptionLocaleTokens}} locale tokens used', {
                userLocaleTokens: user.locale_token_count.toLocaleString('en-US'),
                subscriptionLocaleTokens: user.subscription_tier.locale_token_limit.toLocaleString('en-US')
              })}
            </p>
            <div
              className='action-button primary purchase-subscription'
              onClick={() => navigate(UI_ROUTES.Pricing)}>
              <p className='action-button-text'>{t('Change Subscription')}</p>
            </div>
            <h4 className='payment-method-title'>{t('Payment Method')}</h4>
            {user.has_active_card ? (
              <>
                <div className='active-card'>
                  <p className='card-brand'>{user.active_card_brand}</p>
                  <p className='card-last4'>{`************${user.active_card_last4}`}</p>
                </div>
                <div
                  className='action-button primary update-payment-method'
                  onClick={() => setActiveModal(ModalType.UpdatePaymentMethod)}>
                  <p className='action-button-text'>{t({text: 'Update', context: 'command'})}</p>
                </div>
              </>
            ) : (
              <>
                <p>{t('No active payment method')}</p>
                <div
                  className='action-button primary add-payment-method'
                  onClick={() => setActiveModal(ModalType.UpdatePaymentMethod)}>
                <p className='action-button-text'>{t({text: 'Add Payment Method', context: 'command'})}</p>
                </div>
              </>
            )}
            <h4 className='payments-title'>{t('Payment History')}</h4>
            <DataTable
              className='payments-table'
              headerRow={[t('Placed'), t('Amount'), t('Status')]}
              dataRows={
                user.payments.map((payment, index) => ({
                  items: [
                    moment.unix(payment.created_at).format('LL'),
                    payment.amount,
                    payment.status
                  ]
                }))
              } />
          </>
        ) : (
          <Spinner spinnerType='Chase' />
        )}
      </div>
    </div>
  )
}
