import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIClient } from '../services/APIService';
import { AxiosError } from 'axios';
import { useFlashMessage } from '../hooks/useFlashMessage';
import { useTranslation } from '@blendin/sdk-react';
import { StorageKeys } from '../types/storage';
import { useStorage } from '../hooks/useStorage';

import posthog from 'posthog-js';
import { API_ROUTES, UI_ROUTES } from '../helpers/routes';

export const OAuthGoogleSignupCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const { t } = useTranslation();

  const [userSession, setUserSession] = useStorage(StorageKeys.UserSession);
  const [currentUser, setCurrentUser] = useStorage(StorageKeys.CurrentUser);

  useEffect(() => {
    (async () => {
      try {
        const query = new URLSearchParams(location.search);
        const code = query.get('code');

        const errorParam = query.get('error');

        if (errorParam) {
          navigate(UI_ROUTES.Signup);
        }


        if (code) {
          const userResponse = await APIClient.post(API_ROUTES.OAuthGoogleSignupCallback, {
            code: code
          });
          if (userResponse.status === 200) {
            posthog.identify(userResponse.data.user_uuid, {
              email: userResponse.data.user_email,
              role: userResponse.data.user_role
            });
            setUserSession(userResponse.data.jwt);
            setCurrentUser({
              uuid: userResponse.data.user_uuid,
              email: userResponse.data.user_email,
              role: userResponse.data.user_role
            });
            if (userResponse.data.role === 'translator') {
              navigate(UI_ROUTES.TranslatorDashboard);
            } else {
              navigate(UI_ROUTES.UserDashboard);
            }
          }
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 409) {
          setFlashMessage({ message: t('User exists for google account'), type: 'error' });
          navigate(UI_ROUTES.Signup);
        }
      }
    })();
  }, [location]);

  return null;
}

export const OAuthGoogleLoginCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const { t } = useTranslation();

  const [userSession, setUserSession] = useStorage(StorageKeys.UserSession);
  const [currentUser, setCurrentUser] = useStorage(StorageKeys.CurrentUser);

  useEffect(() => {
    (async () => {
      try {
        const query = new URLSearchParams(location.search);
        const code = query.get('code');
        const errorParam = query.get('error');

        if (errorParam) {
          navigate(UI_ROUTES.Login);
        }

        if (code) {
          const userResponse = await APIClient.post(API_ROUTES.OAuthGoogleLoginCallback, {
            code: code
          });
          if (userResponse.status === 200) {
            posthog.identify(userResponse.data.user_uuid, {
              email: userResponse.data.user_email,
              role: userResponse.data.user_role
            });
            setUserSession(userResponse.data.jwt);
            setCurrentUser({
              uuid: userResponse.data.user_uuid,
              email: userResponse.data.user_email,
              role: userResponse.data.user_role
            });
            if (userResponse.data.role === 'translator') {
              navigate(UI_ROUTES.TranslatorDashboard);
            } else {
              navigate(UI_ROUTES.UserDashboard);
            }
          }
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 404) {
          setFlashMessage({ message: t('User not found'), type: 'error' });
          navigate(UI_ROUTES.Login);
        }
      }
    })();
  }, [location]);

  return null;
}
