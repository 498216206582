export const paginate = <T>(arr: T[], itemsPerPage: number, cursor: number): T[] => {
  const startIndex = cursor * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  if (itemsPerPage > arr.length) {
    return arr;
  }

  return arr.slice(startIndex, endIndex);
}
