import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from '@blendin/sdk-react';
import { UI_ROUTES } from '../helpers/routes';
import { BLENDIN_DOCS_URL } from '../constants';

import waitInLine from '../assets/images/wait-in-line.png';

export const ClosedBetaScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='Screen closed-beta-screen'>

      <div className='wait-in-line-container'>
        <img src={waitInLine} className='wait-in-line-image' />
      </div>

      <h2 className='title-text'>{t(`You're on the list!`)}</h2>

      <p className='subtitle-text'>{t('We are currently in closed Beta, to ensure we can provide support to all of our customers we will send you an email once we are ready for you to onboard.')}</p>

      <div
        className='action-button primary'
        onClick={() => navigate(UI_ROUTES.Home)}>
        <p className='action-button-text'>{t('Return to Homepage')}</p>
      </div>

      <Link className='read-developer-docs' to={BLENDIN_DOCS_URL} target="_blank"> {t('Read developer docs')}</Link>

    </div>
  )

}
