import React, { createContext, useState, useContext } from 'react';
import { ModalType } from '../types/modal';

import { ChangeSubscriptionTierModal } from '../components/modals/ChangeSubscriptionTierModal';
import { CreateApiTokenModal } from '../components/modals/CreateApiTokenModal';
import { EditTranslationModal } from '../components/modals/EditTranslationModal';
import { ConnectGithubRepositoryModal } from '../components/modals/ConnectGithubRepositoryModal';
import { UpdatePaymentMethodModal } from '../components/modals/UpdatePaymentMethodModal';

interface ModalContextProps {
  activeModal: ModalType;
  setActiveModal: (modalType: ModalType, modalProps?: any) => void;
}

export const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode}> = ({ children }) => {
  const [activeModal, setActiveModalState] = useState<ModalType>(ModalType.None);
  const [activeModalProps, setActiveModalProps] = useState<any>({});

  const setActiveModal = (modalType: ModalType, modalProps?: any) => {
    setActiveModalState(modalType);
    setActiveModalProps(modalProps);
  }

  return (
    <ModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
      {activeModal === ModalType.ChangeSubscriptionTier ? (<ChangeSubscriptionTierModal {...activeModalProps} />) : null}
      {activeModal === ModalType.CreateApiToken ? (<CreateApiTokenModal {...activeModalProps} />) : null}
      {activeModal === ModalType.EditTranslation ? (<EditTranslationModal {...activeModalProps} />) : null}
      {activeModal === ModalType.ConnectGithubRepository ? (<ConnectGithubRepositoryModal {...activeModalProps} />) : null}
      {activeModal === ModalType.UpdatePaymentMethod ? (<UpdatePaymentMethodModal {...activeModalProps} />) : null}
    </ModalContext.Provider>
  );
};
