import React, { Dispatch, SetStateAction } from 'react';
interface ToggleSwitchProps {
  options: string[];
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  options,
  selectedOption,
  setSelectedOption
}) => {
  return (
    <div className='Toggle-Switch'>
      {options.map((option: string) => (
        <div
          key={option}
          className={`toggle-option ${option === selectedOption ? 'selected' : ''}`}
          onClick={() => setSelectedOption(option)}>
          <p className='toggle-option-name'>{option}</p>
        </div>
      ))}
    </div>
  )
}
