export const BLENDIN_DOCS_URL = `https://blendin-docs.vercel.app/docs/overview`;
export const CALENDLY_PRODUCT_DEMO_URL = `https://calendly.com/jordan-blendin/blendin-product-demo`;
export const GITHUB_OAUTH_URL = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=user`;

export const BLENDIN_X_URL = `https://x.com/blendin_ai`;
export const BLENDIN_FACEBOOK_URL = `https://www.facebook.com/people/Blendin/61566287475258/`;
export const BLENDIN_LINKEDIN_URL = `https://www.linkedin.com/company/blendin-ai`;

const GOOGLE_OAUTH_SIGNUP_CALLBACK_URL = encodeURIComponent(`${process.env.REACT_APP_FRONTEND_URL}/oauth/google/signup-callback`);
const GOOGLE_OAUTH_LOGIN_CALLBACK_URL = encodeURIComponent(`${process.env.REACT_APP_FRONTEND_URL}/oauth/google/login-callback`);
export const GOOGLE_OAUTH_SIGNUP_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${GOOGLE_OAUTH_SIGNUP_CALLBACK_URL}&response_type=code&scope=email%20profile&access_type=offline`;
export const GOOGLE_OAUTH_LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${GOOGLE_OAUTH_LOGIN_CALLBACK_URL}&response_type=code&scope=email%20profile&access_type=offline`;
