import React from 'react';
import { useTranslation } from '@blendin/sdk-react';

export const ErrorScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='error-screen'>
      <h4>{t('Error Screen')}</h4>
    </div>
  )
}
