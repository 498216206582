import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getStorageValue } from '../helpers/storage';
import { StorageKeys } from '../types/storage';

type ExtendedAxiosInstance = AxiosInstance & {
  authenticatedGet: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>;
  authenticatedPost: (url: string, data?: any, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>;
};

const createAPIClient = (): ExtendedAxiosInstance => {
  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json"
    }
  }) as ExtendedAxiosInstance;

  apiClient.authenticatedGet = async (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
    const userSession = await getStorageValue(StorageKeys.UserSession);

    if (userSession) {

      return apiClient.get(url, {
        ...config,
        headers: {
          ...config?.headers,
          'Authorization': `Bearer ${userSession}`
        }
      });

    } else {
      return Promise.resolve({
        data: {},
        status: 401,
        statusText: 'Unauthorized',
        headers: {},
        config: {},
        request: {}
      } as AxiosResponse);
    }

  }

  apiClient.authenticatedPost = async (url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
    const userSession = await getStorageValue(StorageKeys.UserSession);

    if (userSession) {
      return apiClient.post(url, data, {
        ...config,
        headers: {
          ...config?.headers,
          'Authorization': `Bearer ${userSession}`
        }
      });
    } else {
      return Promise.resolve({
        data: {},
        status: 401,
        statusText: 'Unauthorized',
        headers: {},
        config: {},
        request: {}
      } as AxiosResponse);
    }
  }

  return apiClient as ExtendedAxiosInstance;
}

export const APIClient = createAPIClient();
