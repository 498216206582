import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import posthog from 'posthog-js';
import reportWebVitals from './reportWebVitals';
import './styles/App.scss';

if (process.env.NODE_ENV === 'production') {
  posthog.init('phc_Ksktn2nzyGCABaIh6q2JsuN9TZjbCqxqOsZ6qFTPobK',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always' // 'identified_only' or 'always' to create profiles for anonymous users as well
    }
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
