import React, { useState } from 'react';
import { AllLocalesSelect } from '../components/AllLocalesSelect';
import { useTranslation } from '@blendin/sdk-react';

export const DemoCreateScreen: React.FC = () => {
  const [targetUrl, setTargetUrl] = useState<string>('');
  const [selectedTargetLocales, setSelectedTargetLocales] = useState<string[]>([]);
  const { t } = useTranslation();

  const createDemoProject = async () => {
    console.log(selectedTargetLocales);
  }

  return (
    <div className='Screen demo-create-screen'>
      <div className='content-container'>
        <div className='form-content'>
          <div className='labeled-input demo-project-url'>
            <p className='input-label'>{t('Demo Project URL')}</p>
            <input
              type="text"
              value={targetUrl}
              onChange={(e) => setTargetUrl(e.target.value)} />

          </div>
          <AllLocalesSelect selectedTargetLocales={selectedTargetLocales} setSelectedTargetLocales={setSelectedTargetLocales} />
          <div className='action-buttons'>
            <div
              className='action-button primary'
              onClick={createDemoProject}>
              <p className='action-button-text'>{t('Create Demo Project')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
