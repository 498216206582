import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { displayFlag } from '@blendin/sdk-js';
import { useTranslation } from '@blendin/sdk-react';
import { APIClient } from '../services/APIService';
import { API_ROUTES } from '../helpers/routes';
import { Locale } from '../types/locale';
import { Checkbox } from 'pretty-checkbox-react';

interface AllLocalesSelectProps {
  selectedTargetLocales: string[];
  setSelectedTargetLocales: Dispatch<SetStateAction<string[]>>;
}

export const AllLocalesSelect: React.FC<AllLocalesSelectProps> = ({
  selectedTargetLocales = [],
  setSelectedTargetLocales = () => []
}) => {
  const { t } = useTranslation();
  const [locales, setLocales] = useState<Locale[]>([]);

  useEffect(() => {
    (async () => {
      const localesResponse = await APIClient.get(API_ROUTES.LocalesIndex, {})
      if (localesResponse.status === 200) {
        setLocales(localesResponse.data.locales);
      }
    })();
  }, []);

  const handleTargetLocaleSelect = (nextValue: boolean, iso: string) => {
    if (nextValue) {
      setSelectedTargetLocales([...selectedTargetLocales, iso]);
    } else {
      setSelectedTargetLocales(selectedTargetLocales.filter(value => value !== iso));
    }
  }

  return (
    <div className='all-locales'>
      <p className='input-label'>{t('Target languages')}</p>
      <div className='target-locales-select'>
        <div className='select-locales'>
          {locales.map((locale, index) => (
            <div
              key={index}
              className='target-locale-select'
              onClick={() => {
                const nextValue = !selectedTargetLocales.includes(locale.iso);
                handleTargetLocaleSelect(nextValue, locale.iso);
              }}>
              <Checkbox
                readOnly={true}
                className='checkbox-wrapper'
                checked={selectedTargetLocales.includes(locale.iso)} />
              {displayFlag(locale.iso) ? (
                <img className='locale-flag' src={`https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/${displayFlag(locale.iso).toLowerCase()}.svg`} />
              ) : null}
              <p className=''>{locale.name}</p>
            </div>
          ))}
        </div>
        <div className='selected-locales'>
          {selectedTargetLocales.map((iso, index) => (
            <div
              key={index}
              className='selected-locale'>
              {displayFlag(iso) ? (
                <img className='locale-flag' src={`https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/${displayFlag(iso).toLowerCase()}.svg`} />
              ) : null}
              <p
                key={index}
                className='selected-locale-text'>{locales.find((locale) => locale.iso === iso)!.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

}
