import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageKeys } from '../types/storage';
import { useStorage } from '../hooks/useStorage';

import posthog from 'posthog-js';

export const UserLogoutCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userSession, setUserSession] = useStorage(StorageKeys.UserSession);
  const [currentUser, setCurrentUser] = useStorage(StorageKeys.CurrentUser);

  useEffect(() => {
    (async () => {
      posthog.capture('user_logout', {});
      posthog.reset();
      setUserSession(undefined);
      setCurrentUser(undefined);
      navigate('/');
    })()
  }, [location])

  return null;
}
