import React from 'react';
import { useTranslation } from '@blendin/sdk-react';

export const DocumentationScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='documentation-screen'>
      <div className='content-container-wide'>
        <h2 className='documentation-title'>{t('Documentation Screen')}</h2>
      </div>
    </div>
  )
}
