import React, { useState, useEffect } from 'react';
import { BlendinTranslate, useTranslation } from '@blendin/sdk-react';
import { useNavigate } from 'react-router-dom';
import { UI_ROUTES } from '../helpers/routes';
import { BLENDIN_DOCS_URL, CALENDLY_PRODUCT_DEMO_URL } from '../constants';
import { CodeBlock } from '../components/CodeBlock';

import chevronRight from '../assets/chevron-right.svg';
import checkmark from '../assets/checkmark.svg';
import remove from '../assets/remove.svg';

import appScreenshot from '../assets/images/app-screenshot.png';
import blendinJson from '../assets/images/blendin-json.png';
import selectDown from '../assets/images/select-down.svg';
import whiteGloveService from '../assets/images/white-glove-service.png';
import powerfulIntegrations from '../assets/images/powerful-integrations.png';
import aiNative from '../assets/images/ai-native.png';

import trustedByLogos from '../assets/images/trusted-by-logos.png';
import featuredIntegrationLogo1 from '../assets/images/featured-integration-logo-1.png';
import featuredIntegrationLogo2 from '../assets/images/featured-integration-logo-2.png';
import featuredIntegrationLogo3 from '../assets/images/featured-integration-logo-3.png';
import createProject from '../assets/images/create-project.png';
import taggingHtml from '../assets/images/tagging-html.png';
import taggingReact from '../assets/images/tagging-react.png';
import cliInstallation from '../assets/images/cli-installation.png';
import cliUsage from '../assets/images/cli-usage.png';

export const HomeScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [gettingStartedActiveLanguage, setGettingStartedActiveLanguage] = useState<string>('React');
  const gettingStartedLanguages = {
    popularLanguages: [
      'React',
      'Next.js',
      'Javascript',
      'HTML'
    ],
    documentationFrameworks: [
      'Docusaurus',
      'Gitbook'
    ]
  }
  return (
    <div className='Screen home-screen'>
      <div className='hero primary'>
        <div className='text-content'>
          <h2 className='hero-title'>{t('AI-Powered App Translation in Seconds')}</h2>
          <p className='hero-subtitle'>{t('Blendin is the simplest workflow for integrating multilingual content into your app.')}</p>
        </div>
        <div className='action-buttons'>
          <div
            className='action-button primary call-to-action'
            onClick={() => navigate(UI_ROUTES.Signup)}>
            <p className='action-button-text'>{t('Get started for free')}</p>
          </div>
          <p className='or'>or</p>
          {/* <div className='flex-spacer' /> */}
          <h4
            className='secondary-call-to-action action-text'
            onClick={() => window.Calendly.initPopupWidget({
              url: CALENDLY_PRODUCT_DEMO_URL
            })}>
              {t('Schedule a Demo')}
          </h4>
        </div>
      </div>

      {/* <div className="oauth-divider">
        <div className="divider fade-out flex-spacer" />
      </div> */}

      <div className='app-screenshot-container'>
        <img src={appScreenshot} className='app-screenshot' />
      </div>

      <div className='getting-started-container'>
        <h2 className='getting-started-title'>{t('Getting Started With Blendin Is Easy')}</h2>
        <p className='getting-started-subtitle'>{t('We support a range of programming languages and use cases. Get started with just a few lines of code.')}</p>
        <select
          className='getting-started-language-select'
          value={gettingStartedActiveLanguage}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setGettingStartedActiveLanguage(e.target.value)}}
          style={{
            backgroundImage: `url(${selectDown})`,
            backgroundPosition: 'right 0.5em top 50%',
            backgroundRepeat: 'no-repeat'
          }}>
          <optgroup label="Popular Languages">
            {gettingStartedLanguages.popularLanguages.map((language: string) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </optgroup>
          <optgroup label='Documentation Frameworks'>
            {gettingStartedLanguages.documentationFrameworks.map((language: string) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </optgroup>
        </select>

        <div className='language-specific-code'>
          {['React', 'Next.js', 'Javascript', 'Gitbook'].includes(gettingStartedActiveLanguage) ? (
            <>
              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>1</p>
                  </div>
                  <p className='instructions'>Install the Blendin CLI and React SDK</p>
                </div>
                <CodeBlock
                  language='bash'
                  code={`npm install -g @blendin/cli
npm install @blendin/sdk-react`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>2</p>
                  </div>
                  <p className='instructions'>Wrap your application in the BlendinProvider</p>
                </div>
                <CodeBlock
                  language='jsx'
                  code={`import { BlendinProvider } from '@blendin/sdk-react';
import blendinConfig from './blendin.json';

export const App = () => {
  return (
    <BlendinProvider config={blendinConfig}>
              <HomeScreen />
          </BlendinProvider>
  )
}
`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>3</p>
                  </div>
                  <p className='instructions'>Use the <code>t</code> hook to tag text you want translated</p>
                </div>
                <CodeBlock
                  language='jsx'
                  code={`import { useTranslation } from '@blendin/sdk-react';

export const HomeScreen = () => {
  const { t } = useTranslation();

  return (
    <div>
              <p>{t('This text will be automatically translated by Blendin')}</p>
           </div>
  )
}
`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>4</p>
                  </div>
                  <p className='instructions'>Run <code>blendin translate</code> to generate the translation files</p>
                </div>
                <CodeBlock
                  language='bash'
                  code={'blendin translate'} />
              </div>
            </>
          ) : null}

          {gettingStartedActiveLanguage === 'HTML' ? (
            <>
              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>1</p>
                  </div>
                  <p className='instructions'>Install the Blendin CLI</p>
                </div>
                <CodeBlock
                  language='bash'
                  code={`npm install -g @blendin/cli`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>2</p>
                  </div>
                  <p className='instructions'>Tag the strings you want to be translated</p>
                </div>
                <CodeBlock
                  language='html'
                  code={`<html>
  <body>
    <p data-blendin-translate>
      This text will be automatically translated by Blendin
    </p>

    <p class="blendin-translate">
      This text will also be translated by Blendin
    </p>
  </body>
</html>`} />

              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>3</p>
                  </div>
                  <p className='instructions'>Run <code>blendin translate</code> to generate the translation files</p>
                </div>
                <CodeBlock
                  language='bash'
                  code={'blendin translate'} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>4</p>
                  </div>
                  <p className='instructions'>Add the javascript snippet to your HTML code</p>
                </div>
                <CodeBlock
                  language='html'
                  code={`<html>
  <head>

    <script src='https://cdn.jsdelivr.net/npm/@blendin/sdk-js/dist/bundle.js'></script>
    <script>
            (async () => {
                const blendinConfig = await fetch("./blendin.json").then(response => response.json());
                blendin.init(blendinConfig);
            })();
        </script>
   <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@blendin/sdk-js/dist/styles.css" />

  </head>
</html>`} />
              </div>
            </>
          ) : null}

          {gettingStartedActiveLanguage === 'Docusaurus' ? (
            <>
              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>1</p>
                  </div>
                  <p className='instructions'>Install the Blendin CLI and Docusaurus Plugin</p>
                </div>
                <CodeBlock
                  language='bash'
                  code={`npm install -g @blendin/cli
npm install @blendin/plugin-docusaurus`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>2</p>
                  </div>
                  <p className='instructions'>Add the blendin plugin to the <code>docusaurus.config.js</code></p>
                </div>
                <CodeBlock
                  language='javascript'
                  code={`const blendinConfig = {
  project: {
    projectId: '<YOUR_PROJECT_ID>',
    apiToken: '<YOUR_API_TOKEN>'
  }
}

plugins: [['@blendin/plugin-docusaurus', blendinConfig]]
`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>3</p>
                  </div>
                  <p className='instructions'>Set your target locales in the <code>i18n</code> section of <code>docusaurus.config.js</code></p>
                </div>
                <CodeBlock
                  language='javascript'
                  code={`i18n: {
  defaultLocale: 'en',
  locales: ['en', 'es', 'fr']
}`} />
              </div>

              <div className='code-block-step'>
                <div className='code-block-step-meta'>
                  <div className='number-container'>
                    <p className='number'>4</p>
                  </div>
                  <p className='instructions'>Run the blendin translation command via the docusaurus pipeline</p>
                </div>
                <CodeBlock
                  language='bash'
                  code={`npx docusaurus blendin-translate`} />
              </div>

            </>
          ) : null}
        </div>

        <p className='outro-text'>
          That's it. Check out our <a href={BLENDIN_DOCS_URL} target="_blank">documentation</a> if you have any questions.
        </p>

        <div className='action-buttons secondary'>
          <div
            className='action-button primary'
            onClick={() => navigate(UI_ROUTES.Signup)}>
            <p className='action-button-text'>{t('Create an Account')}</p>
          </div>
          <div
            className='action-button tertiary book-demo'
            onClick={() => window.Calendly.initPopupWidget({
              url: CALENDLY_PRODUCT_DEMO_URL
            })}>
            <p className='action-button-text'>{t('Book a Demo')}</p>
          </div>
        </div>

      </div>

      <div className='features-container'>
        <div className='feature'>
          <div className='feature-text'>
            <h3 className='feature-title'>{t('White-Glove Service')}</h3>
            <p className='feature-description'>{t("Don't waste time wiring together your own localization set up. Blendin provides an opinionated service where the entire process can be managed from a single config file.")}</p>
          </div>
          <div className='flex-spacer' />
          <img className='feature-image white-glove-service' src={whiteGloveService} />
        </div>
        <div className='feature inverted'>
          <div className='feature-text'>
            <h3 className='feature-title'>{t('Powerful Integrations')}</h3>
            <p className='feature-description'>{t("Whether it's integrating your Github repo for continuous updates. Or uploading your documents from Docusaurus, Blendin makes it easy to work with the developer tools you already love.")}</p>
          </div>
          <div className='flex-spacer' />
          <img className='feature-image powerful-integrations' src={powerfulIntegrations} />
        </div>
        <div className='feature'>
          <div className='feature-text'>
            <h3 className='feature-title'>{t('AI-Native')}</h3>
            <p className='feature-description'>{t("Blendin leverages state of the art LLMs and are constantly improving translation quality. Save on tokens with our centralized database of translations.")}</p>
          </div>
          <div className='flex-spacer' />
          <img className='feature-image ai-native' src={aiNative} />
        </div>
      </div>

      {/* <div className='trusted-by-container'>
        <h2 className='trusted-by-text'>{t('The best localization product for startups')}</h2>
        <p className='trusted-by-sales-copy'>{t('Don’t waste thousands of dollars and resources on human-powered translation. With Blendin, developers can manage the entire internationalization process from a single config file.')}</p>
        <img src={trustedByLogos} className='trusted-by-logos' />
      </div> */}

      {/* <div className='featured-integrations-container'>
        <h2 className='featured-integrations-title'>{t('Featured Plugins')}</h2>
        <div className='featured-integrations-logos'>
          <img src={featuredIntegrationLogo1} className='featured-integrations-logo' />
          <img src={featuredIntegrationLogo2} className='featured-integrations-logo' />
          <img src={featuredIntegrationLogo3} className='featured-integrations-logo' />
        </div>
      </div> */}

      {/* <div className='pricing-container'>
        <h2 className='pricing-title'>{t('Pricing & Plans')}</h2>
        <div className='pricing-tiers'>
          <div className='pricing-tier free'>
            <p className='pricing-tier-title'>{t('FREE')}</p>
            <BlendinTranslate>
              <span className='dollar-sign'>$</span>
              <span className='amount-per-month'>0</span>
              <span className='per-month'> / month</span>
            </BlendinTranslate>
            <div className='tier-features'>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Up to 2 locales')}</p>
              </div>
              <div className='tier-feature enabled'>
              <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('{{numberOfWords}} per month', {numberOfWords: '2,000'})}</p>
              </div>
              <div className='tier-feature disabled'>
                <img src={remove} className='icon-disabled' />
                <p className='tier-feature-text'>{t('Multiple users per project')}</p>
              </div>
              <div className='tier-feature disabled'>
                <img src={remove} className='icon-disabled' />
                <p className='tier-feature-text'>{t('Human QA of translations')}</p>
              </div>
              <div className='tier-feature disabled'>
                <img src={remove} className='icon-disabled' />
                <p className='tier-feature-text'>{t('Premium support')}</p>
              </div>
            </div>
          </div>
          <div className='pricing-tier pro'>
            <p className='pricing-tier-title'>{t('PRO')}</p>
            <BlendinTranslate>
              <span className='dollar-sign'>$</span>
              <span className='amount-per-month'>19</span>
              <span className='per-month'> / month</span>
            </BlendinTranslate>
            <div className='tier-features'>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Unlimited locales')}</p>
              </div>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('{{numberOfWords}} per month', {numberOfWords: '10,000'})}</p>
              </div>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Unlimited users per project')}</p>
              </div>
              <div className='tier-feature disabled'>
                <img src={remove} className='icon-disabled' />
                <p className='tier-feature-text'>{t('Human QA of translations')}</p>
              </div>
              <div className='tier-feature disabled'>
                <img src={remove} className='icon-disabled' />
                <p className='tier-feature-text'>{t('Premium support')}</p>
              </div>
            </div>
          </div>
          <div className='pricing-tier elite'>
            <p className='pricing-tier-title'>{t('ELITE')}</p>
            <BlendinTranslate>
              <span className='dollar-sign'>$</span>
              <span className='amount-per-month'>49</span>
              <span className='per-month'> / month</span>
            </BlendinTranslate>
            <div className='tier-features'>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Unlimited locales')}</p>
              </div>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Unlimited words per month')}</p>
              </div>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Unlimited users per project')}</p>
              </div>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Human QA of translations')}</p>
              </div>
              <div className='tier-feature enabled'>
                <img src={checkmark} className='icon-enabled' />
                <p className='tier-feature-text'>{t('Premium support')}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='testimonials-container'>
      </div> */}
    </div>
  )

{/* <div className="oauth-divider">
  <div className="divider fade-out flex-spacer" />
</div> */}

}
