import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { UI_ROUTES } from './helpers/routes';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { FlashMessage } from './components/FlashMessage';

import { ClosedBetaScreen } from './screens/ClosedBetaScreen';
import { DemoCreateScreen } from './screens/DemoCreateScreen';
import { PricingScreen } from './screens/PricingScreen';
import { ErrorScreen } from './screens/ErrorScreen';
import { HomeScreen } from './screens/HomeScreen';
import { DocumentationScreen } from './screens/DocumentationScreen';
import { LoginScreen } from './screens/LoginScreen';
import { PrivacyPolicyScreen } from './screens/PrivacyPolicyScreen';
import { ProjectCreateScreen } from './screens/ProjectCreateScreen';
import { ProjectDashboardScreen } from './screens/ProjectDashboardScreen';
import { ProjectLocaleShowScreen } from './screens/ProjectLocaleShowScreen';
import { ProjectTranslationJobCreateScreen } from './screens/ProjectTranslationJobCreateScreen';
import { ProjectTranslationJobShowScreen } from './screens/ProjectTranslationJobShowScreen';
import { SignupScreen } from './screens/SignupScreen';
import { TermsOfServiceScreen } from './screens/TermsOfServiceScreen';
import { TranslatorConfirmTranslationsScreen } from './screens/TranslatorConfirmTranslationsScreen';
import { TranslatorDashboardScreen } from './screens/TranslatorDashboardScreen';
import { UserDashboardScreen } from './screens/UserDashboardScreen';
import { UserSettingsScreen } from './screens/UserSettingsScreen';

import { UserSignupCallback } from './callbacks/UserSignupCallback';
import { UserLogoutCallback } from './callbacks/UserLogoutCallback';
import { OAuthGoogleSignupCallback, OAuthGoogleLoginCallback } from './callbacks/OAuthGoogleCallback';

import { BlendinProvider } from '@blendin/sdk-react';

import { FlashMessageProvider } from './contexts/FlashMessageContext';
import { ModalProvider } from './contexts/ModalContext';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import blendinConfig from './blendin.json';
import posthog from 'posthog-js';
import { BlendinConfig } from '@blendin/types';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY}`);

interface LocationListenerProps {
  setUserDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setLanguageDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setHamburgerDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

const LocationListener: React.FC<LocationListenerProps> = ({
  setUserDropdownOpen = () => undefined,
  setLanguageDropdownOpen = () => undefined,
  setHamburgerDropdownOpen = () => undefined
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    posthog.capture('$pageview');

    setUserDropdownOpen(false);
    setLanguageDropdownOpen(false);
    setHamburgerDropdownOpen(false);
  }, [location])

  return null;
}

const App: React.FC = () => {
  const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState<boolean>(false);
  const [hamburgerDropdownOpen, setHamburgerDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.user-dropdown-menu') && !target.closest('.current-user-container')) {
        setUserDropdownOpen(false);
      }
    }
    if (userDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [userDropdownOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.blendin-dropdown-menu') && !target.closest('.blendin-locale-select')) {
        setLanguageDropdownOpen(false);
      }
    }
    if (languageDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [languageDropdownOpen]);

  return (
    <div className='Container'>
      <Router>
        <BlendinProvider config={blendinConfig as BlendinConfig}>
          <FlashMessageProvider>
            <Elements stripe={stripePromise}>
              <ModalProvider>
                <div className='App'>
                  <LocationListener
                      setUserDropdownOpen={setUserDropdownOpen}
                      setLanguageDropdownOpen={setLanguageDropdownOpen}
                      setHamburgerDropdownOpen={setHamburgerDropdownOpen} />
                  <Header
                    userDropdownOpen={userDropdownOpen}
                    setUserDropdownOpen={setUserDropdownOpen}
                    languageDropdownOpen={languageDropdownOpen}
                    setLanguageDropdownOpen={setLanguageDropdownOpen}
                    hamburgerDropdownOpen={hamburgerDropdownOpen}
                    setHamburgerDropdownOpen={setHamburgerDropdownOpen} />
                  <div className='Screen'>
                    <FlashMessage />
                    <Routes>
                      <Route path={UI_ROUTES.ClosedBeta} element={<ClosedBetaScreen />} />
                      <Route path={UI_ROUTES.DemoCreate} element={<DemoCreateScreen />} />
                      <Route path={UI_ROUTES.Documentation} element={<DocumentationScreen />} />
                      <Route path={UI_ROUTES.Error} element={<ErrorScreen />} />
                      <Route path={UI_ROUTES.Home} element={<HomeScreen />} />
                      <Route path={UI_ROUTES.Login} element={<LoginScreen />} />
                      <Route path={UI_ROUTES.LogoutCallback} element={<UserLogoutCallback />} />
                      <Route path={UI_ROUTES.OAuthGoogleLoginCallback} element={<OAuthGoogleLoginCallback />} />
                      <Route path={UI_ROUTES.OAuthGoogleSignupCallback} element={<OAuthGoogleSignupCallback />} />
                      <Route path={UI_ROUTES.Pricing} element={<PricingScreen />} />
                      <Route path={UI_ROUTES.PrivacyPolicy} element={<PrivacyPolicyScreen />} />
                      <Route path={UI_ROUTES.ProjectCreate} element={<ProjectCreateScreen />} />
                      <Route path={UI_ROUTES.ProjectDashboard} element={<ProjectDashboardScreen />} />
                      <Route path={UI_ROUTES.ProjectLocaleShow} element={<ProjectLocaleShowScreen />} />
                      <Route path={UI_ROUTES.ProjectTranslationJobCreate} element={<ProjectTranslationJobCreateScreen />} />
                      <Route path={UI_ROUTES.ProjectTranslationJobShow} element={<ProjectTranslationJobShowScreen />} />
                      <Route path={UI_ROUTES.Signup} element={<SignupScreen />} />
                      <Route path={UI_ROUTES.TermsOfService} element={<TermsOfServiceScreen />} />
                      <Route path={UI_ROUTES.TranslatorConfirmTranslations} element={<TranslatorConfirmTranslationsScreen />} />
                      <Route path={UI_ROUTES.TranslatorDashboard} element={<TranslatorDashboardScreen />} />
                      <Route path={UI_ROUTES.UserDashboard} element={<UserDashboardScreen />} />
                      <Route path={UI_ROUTES.UserSettings} element={<UserSettingsScreen />} />
                      <Route path={UI_ROUTES.UserSignupCallback} element={<UserSignupCallback />} />
                    </Routes>
                  </div>
                  <Footer />
                </div>
              </ModalProvider>
            </Elements>
          </FlashMessageProvider>
        </BlendinProvider>
      </Router>
    </div>
  )
}

export default App;
