import React, { useEffect, useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { useParams } from 'react-router-dom';
import { APIClient } from '../services/APIService';
import { useNavigate } from 'react-router-dom';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { ProjectLocale } from '../types/project_locale';
import { truncate } from '../helpers/strings';
import { DataTable } from '../components/DataTable';

export const ProjectLocaleShowScreen: React.FC = () => {
  const { t } = useTranslation();
  const { uuid, iso } = useParams();
  const navigate = useNavigate();

  const [projectLocale, setProjectLocale] = useState<ProjectLocale | undefined>(undefined);

  const createTranslation = async () => {

    const apiResponse = await APIClient.authenticatedPost(API_ROUTES.ProjectLocaleTranslationJobCreate
      .replace(':uuid', uuid!)
      .replace(':iso', iso!),
      {},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

  }

  useEffect(() => {
    (async () => {

      try {
        const projectLocaleResponse = await APIClient.authenticatedGet(API_ROUTES.ProjectLocaleShow.replace(':uuid', uuid!).replace(':iso', iso!));

        if (projectLocaleResponse.status === 200) {
          setProjectLocale(projectLocaleResponse.data.project_locale);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          navigate(UI_ROUTES.Login);
        } else if (error.response.status === 404) {
          navigate(UI_ROUTES.UserDashboard);
        }
      }

    })();
  }, []);

  return (
    <div className='project-locale-show-screen'>
      <div className='content-container-wide'>
        {projectLocale ? (
          <>
            <div className='project-locale-name-container'>
              <h2 className='project-locale-name'>{projectLocale.name}</h2>
              <p className='project-locale-iso circled-label'>{projectLocale.iso}</p>
            </div>
            <div className='translation-strings-container'>
              <div className='translations-header-container'>
                <h3 className='translations-title'>{t('Translations')}</h3>
                <div className='flex-spacer' />
                <div
                  className='action-button primary'
                  onClick={createTranslation}>
                  <p className='action-button-text'>{t({text: 'Create Translation', context: 'command'})}</p>
                </div>
              </div>
              <DataTable
                className='translations-table'
                headerRow={[t('Source string'), t('Translation string'), t('Verification')]}
                itemsPerPage={30}
                dataRows={
                  projectLocale.translation_strings.map((translationString, index) => ({
                    items: [
                      truncate(translationString.source_string_value, 42),
                      truncate(translationString.translation_string_value ?? '-', 84),
                      translationString.confirmation_state
                    ]
                  }))
                }
              />
            </div>
          </>
        ) : (<></>)}
      </div>
    </div>
  )
}
