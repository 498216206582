import React, { useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { TranslationString } from '../../types/translation_string';
import { ModalType } from '../../types/modal';
import { useModal } from '../../hooks/useModal';
import { APIClient } from '../../services/APIService';
import { API_ROUTES } from '../../helpers/routes';
import { Spinner } from '../../components/Spinner';

interface ModalProps {
  translationString: TranslationString;
}

export const EditTranslationModal: React.FC<ModalProps> = ({
  translationString
}) => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newTranslation, setNewTranslation] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  const closeModalIfElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setActiveModal(ModalType.None);
    }
  }

  const handleEditTranslation = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsLoading(true);
    const apiResponse = await APIClient.authenticatedPost(API_ROUTES.TranslationStringEditTranslation, {
      translation_string_uuid: translationString.uuid,
      next_translation_string_value: newTranslation,
      notes: notes
    })

    if (apiResponse.status === 200) {
      setIsLoading(false);
      setActiveModal(ModalType.None);
    }
  }

  return (
    <div
      className='Modal edit-translation'
      onClick={closeModalIfElementClick}>
      <div className='modal-content'>
        <div className='modal-title-container'>
          <h3 className='modal-title'>{t('Edit Translation')}</h3>
          <div className='flex-spacer' />
          <p
            className='modal-close'
            onClick={closeModalIfElementClick}>
            X
          </p>
        </div>
        <div className='modal-body'>
          {isLoading ? (
            <Spinner spinnerType='Chase' />
          ) : (
            <>
              <div className='labeled-input'>
                <p className='input-label'>{t('Current Translation')}</p>
                <input
                  type='text'
                  value={translationString.translation_string_value}
                  disabled={true} />
              </div>
              <div className='labeled-input'>
                <p className='input-label'>{t('New Translation')}</p>
                <input
                  type='text'
                  value={newTranslation}
                  onChange={(e) => setNewTranslation(e.target.value)} />
              </div>
              <div className='labeled-input'>
                <p className='input-label'>{t('Notes')}</p>
                <input
                  type='text'
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)} />
              </div>
            </>
          )}
        </div>
        <div className='modal-actions'>
          {isLoading ? null : (
            <>
              <div
                className='action-button tertiary'
                onClick={() => setActiveModal(ModalType.None)}>
                <p className='action-button-text'>{t({text: 'Close', context: 'command'})}</p>
              </div>
              <div
                className='action-button primary'
                onClick={handleEditTranslation}>
                <p className='action-button-text'>{t({text: 'Save', context: 'command'})}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
