import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { displayFlag } from '@blendin/sdk-js';
import { useParams } from 'react-router-dom';
import { APIClient } from '../services/APIService';
import { useNavigate } from 'react-router-dom';
import { SubNavigation } from '../components/SubNavigation';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { ProjectDashboard } from '../types/project';
import { truncate } from '../helpers/strings';
import { DataTable } from '../components/DataTable';
import { Spinner } from '../components/Spinner';
import moment from 'moment';
import { useModal } from '../hooks/useModal';
import { ModalType } from '../types/modal';

import copyIcon from '../assets/copy-icon.svg';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-textmate';

interface ProjectDashboardFilesProps {
  project: ProjectDashboard | undefined;
  setProject: any;
}
const ProjectDashboardFiles: React.FC<ProjectDashboardFilesProps> = ({
  project,
  setProject
}) => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files ? e.target.files[0] : null;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const apiResponse = await APIClient.post(API_ROUTES.ProjectUploadFile.replace(':uuid', uuid!), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (apiResponse.status === 201) {
        let nextProject = project!;
        nextProject.source_files.push({
          uuid: apiResponse.data.uuid,
          name: apiResponse.data.filename,
          active_file_version: 0,
          source_file_versions_count: 1
        })
        setProject(nextProject);
      }
    }
  }

  return (
    <>
      <div className='header-container files-header-container'>
        <h3 className='files-title'>{t('Files')}</h3>
        <div className='flex-spacer' />
        <input type='file' ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
        <div
          className='action-button primary'
          onClick={() => fileInputRef.current?.click()}>
          <p className='action-button-text'>{t('Add File')}</p>
        </div>
      </div>
      <DataTable
        className='files-table'
        headerRow={[t('Name'), t('Strings'), t('Revision')]}
        dataRows={
          project!.source_files.map((sourceFile, index) => ({
            onDoubleClickRow: async () => {
              await APIClient.post(API_ROUTES.SourceFileTranslationJobCreate
                .replace(':project_uuid', uuid!)
                .replace(':source_file_uuid', sourceFile.uuid)
                .replace(':version', `${sourceFile.source_file_versions_count}`)
              )
            },
            items: [
              sourceFile.name,
              sourceFile.strings_count,
              sourceFile.source_file_versions_count
            ]
          }))
        } />
    </>
  )
}

interface ProjectDashboardStringsProps {
  project: ProjectDashboard | undefined;
}

const ProjectDashboardStrings: React.FC<ProjectDashboardStringsProps> = ({ project }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className='strings-title'>{t('Strings')}</h3>
      <DataTable
        className='strings-table'
        headerRow={[t('String value'), t('String key'), t('Context'), t('Actions')]}
        itemsPerPage={30}
        dataRows={
          project!.source_strings.map((sourceString, index) => ({
            items: [
              truncate(sourceString.string_value, 42),
              truncate(sourceString.string_key, 42),
              sourceString.context,
              undefined
            ]
          }))
        } />
    </>
  )
}

interface ProjectDashboardConnectionsProps {
  project: ProjectDashboard | undefined;
}

const ProjectDashboardConnections: React.FC<ProjectDashboardConnectionsProps> = ({ project }) => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();

  const getUserRepositories = async () => {
    setActiveModal(ModalType.ConnectGithubRepository, {
      project: project
    })
  }

  return (
    <>
      <div className='header-container files-header-container'>
        <h3 className='connections-title'>{t('Connections')}</h3>
        <div className='flex-spacer' />
        <div
          className='action-button primary'
          onClick={getUserRepositories}>
          <p className='action-button-text'>{t('Add Connection')}</p>
        </div>
      </div>
      <DataTable
        className='connections-table'
        headerRow={[t({text: 'Repo Name', context: 'Repo is short for Repository'}), t('Base Branch'), t({text: 'PR Branch', context: 'PR is short for Pull Request'}), t('Locales Path')]}
        dataRows={
          project!.project_github_repository_connections.map((projectGithubRepositoryConnection, index) => ({
            onClickRow: () => {},
            items: [
              projectGithubRepositoryConnection.github_repository.name,
              projectGithubRepositoryConnection.base_branch_name,
              projectGithubRepositoryConnection.pr_branch_name,
              projectGithubRepositoryConnection.locales_path
            ]
          }))
        } />
    </>
  )
}

interface ProjectDashboardTranslationJobsProps {
  project: ProjectDashboard | undefined;
}

const ProjectDashboardTranslationJobs: React.FC<ProjectDashboardTranslationJobsProps> = ({
  project
}) => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className='header-container translation-jobs-header-container'>
        <h3 className='translation-jobs-title'>{t('Translation Jobs')}</h3>
        <div className='flex-spacer' />
        <div
          className='action-button primary'
          onClick={() => navigate(UI_ROUTES.ProjectTranslationJobCreate.replace(':uuid', uuid!))}>
          <p className='action-button-text'>{t('Create Translation Job')}</p>
        </div>
      </div>
      <DataTable
        className='translation-jobs-table'
        headerRow={[t('ID'), t('Name'), t('Status')]}
        dataRows={
          project!.translation_jobs.map((translationJob, index) => ({
            onClickRow: () => navigate(
              UI_ROUTES.ProjectTranslationJobShow
                .replace(':project_uuid', uuid!)
                .replace(':tranlsation_job_uuid', translationJob.uuid)
              ),
            items: [
              translationJob.uuid,
              translationJob.name,
              translationJob.status
            ]
          }))
        } />
    </>
  )
}

interface ProjectDashboardConfigProps {
  project: ProjectDashboard | undefined;
}

const ProjectDashboardConfig: React.FC<ProjectDashboardConfigProps> = ({
  project
}) => {
  const { t } = useTranslation();

  const jsonBlob = JSON.stringify({
    "project": {
      "projectId": project?.uuid,
      "apiToken": project?.current_user_api_token ?? "YOUR_API_TOKEN"
    },
    "locales": {
      "sourceLocale": project?.source_locale.iso,
      "defaultLocale": project?.source_locale.iso,
      "targetLocales": project?.project_locales.map((projectLocale, index) => projectLocale.iso)
    },
    "detection": {
      "localeDetectionOrder": ["queryString", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"]
    },
    "parsing": {
      "sourceFilePaths": [
        "./**/*.html",
        "./**/*.jsx",
        "./**/*.tsx"
      ],
      "stringFilePaths": [],
      "translationsSavePath": "./locales",
      "translationsLoadPath": "./locales"
    },
    "integrations": {}
  }, null, 2);

  const snippetBlob = `
  <script src='https://cdn.jsdelivr.net/npm/@blendin/sdk-js/dist/bundle.js'></script>
  <script>
    (async () => {
        const blendinConfig = await fetch("./blendin.json").then(response => response.json());
        blendin.init(blendinConfig);
    })();
  </script>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@blendin/sdk-js/dist/styles.css" />
  `;

  const yamlBlob = `
name: Blendin Upload Strings

on:
  push:
    branches: [main]

jobs:
  sync-strings-to-blendin:
    runs-on: ubuntu-latest

    env:
      GITHUB_TOKEN: \${{ secrets.GITHUB_TOKEN }}

    steps:

      - name: Initialize Environment
        uses: actions/checkout@v2

      - name: Setup Node.js v18
        uses: actions/setup-node@v2
        with:
          node-version: '18'

      - name: Sync Strings to blendin
        uses: ai18n-xyz/github-action@0.0.22
        with:
          source_path: './src/'
          config_file_path: './src/blendin.json'
`;

  return (
    <>
      <div className='config-files-container'>
        <div className='config-file'>
          <div className='config-title-container'>
            <h3 className='config-title'>{`blendin.json`}</h3>
            <img
              onClick={() => {
                navigator.clipboard.writeText(jsonBlob);
              } }
              className="copy-icon"
              src={copyIcon} />
          </div>
          <div className='config-display'>
            <AceEditor
              mode='json'
              theme='textmate'
              value={jsonBlob}
              readOnly={true}
              fontSize={14}
              showGutter={false}
              showPrintMargin={false}
              highlightActiveLine={false}
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              style={{}} />
          </div>
        </div>
        <div className='config-file'>
          <div className='config-title-container'>
            <h3 className='config-title'>{`Javascript Snippet`}</h3>
            <img
              onClick={() => {
                navigator.clipboard.writeText(snippetBlob);
              } }
              className="copy-icon"
              src={copyIcon} />
          </div>
          <div className='config-display'>
            <AceEditor
              mode='text'
              theme='textmate'
              value={snippetBlob}
              readOnly={true}
              fontSize={14}
              showGutter={false}
              showPrintMargin={false}
              highlightActiveLine={false}
              setOptions={{
                useWorker: false,
                showLineNumbers: false
              }}
              style={{}} />
          </div>
        </div>
      </div>
    </>
  )
}

interface ProjectActivitiesProps {
  project: ProjectDashboard | undefined;
}

const ProjectActivities: React.FC<ProjectActivitiesProps> = ({
  project
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className='activities-title'>{t('Activities')}</h3>
      <DataTable
        className='activities-table'
        headerRow={[t('Activity Type'), t('Activity Data'), t('Timestamp'), t('uuid')]}
        itemsPerPage={30}
        dataRows={
          project!.project_activities.map((activity, index) => ({
            items: [
              activity.activity_type,
              truncate(activity.activity_data, 42),
              activity.timestamp,
              activity.uuid
            ]
          }))
        } />
    </>
  )

}


export const ProjectDashboardScreen: React.FC = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState<ProjectDashboard | undefined>(undefined);

  useEffect(() => {
    (async () => {

      try {
        const projectResponse = await APIClient.authenticatedGet(API_ROUTES.ProjectDashboard.replace(':uuid', uuid!));
        if (projectResponse.status === 200) {
          setProject(projectResponse.data.project as ProjectDashboard)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          navigate(UI_ROUTES.Login);
        } else if (error.response.status === 404) {
          navigate(UI_ROUTES.UserDashboard);
        }
      }

    })();
  }, []);

  return (
    <div className='Screen project-dashboard-screen'>
      <div className='content-container-wide'>
        {project ? (
          <>
            <div className='project-name-container'>
              <h2 className='project-name'>{project.name}</h2>
              <p className='project-privacy-mode circled-label'>{project.privacy_mode}</p>
            </div>
            <div className='project-content'>
              <div className='project-locales'>
                {project.project_locales.map((project_locale, index) => (
                  <div
                    key={index}
                    className='project-locale'
                    onClick={() => {
                      navigate(
                        UI_ROUTES.ProjectLocaleShow
                          .replace(':uuid', project.uuid)
                          .replace(':iso', project_locale.iso)
                      )
                    }}>
                    {displayFlag(project_locale.iso) ? (
                      <img className='locale-flag' src={`https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/${displayFlag(project_locale.iso).toLowerCase()}.svg`} />
                    ) : (
                      <div className='locale-iso-wrapper'>
                        <p className='locale-iso'>{project_locale.iso}</p>
                      </div>
                    )}
                    <p className='locale-name'>{project_locale.name}</p>
                    <div className='flex-spacer' />
                    <div className='translation-progress-bar-container'>
                      <div
                        className='translation-progress-bar-translation-percentage'
                        style={{ width: `${project_locale.translation_completion_percentage}%` }} />
                      <div
                        className='translation-progress-bar-confirmation-percentage'
                        style={{ width: `${project_locale.verification_completion_percentage}%` }} />
                    </div>
                    <div className='translation-progress-text-container'>
                      <p className='translation-progress-text'>{`${project_locale.translation_completion_percentage}%`}</p>
                      <p className='translation-progress-text'>|</p>
                      <p className='translation-progress-text'>{`${project_locale.verification_completion_percentage}%`}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className='flex-spacer' />
              <div className='project-summary'>
                <p className='project-summary-title'>{t('Project Details')}</p>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Source language')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{project.source_locale.name}</p>
                </div>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Number of locales')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{project.project_locales.length}</p>
                </div>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Files to translate')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{project.source_files.length}</p>
                </div>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Strings to translate')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{project.source_strings.length}</p>
                </div>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Tokens to translate')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>
                    {
                      project.source_strings.reduce((total, sourceString) => {
                        return total + sourceString.token_count;
                      }, 0)
                    }
                  </p>
                </div>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Locale tokens')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{project.locale_token_count}</p>
                </div>
                <div className='project-detail-row section-spacing'>
                  <p className='project-detail-label'>{t('Created')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{moment.unix(project.created_at).fromNow()}</p>
                </div>
                <div className='project-detail-row'>
                  <p className='project-detail-label'>{t('Last activity')}</p>
                  <div className='flex-spacer' />
                  <p className='project-detail-value'>{moment.unix(project.updated_at).fromNow()}</p>
                </div>
              </div>
            </div>
          </>
        ) : (<></>)}
      </div>
      <div className='content-container-wide'>
        {project ? (
          <SubNavigation
            navItems={[
              t('Files'),
              t('Strings'),
              t('Connections'),
              // t('Translation Jobs'),
              // t('QA Jobs'),
              t({text: 'Config', context: 'abbreviation for configuration'}),
              t('Activity Log')
            ]}
            navComponents={[
              <ProjectDashboardFiles
                project={project}
                setProject={setProject} />,
              <ProjectDashboardStrings
                project={project} />,
              <ProjectDashboardConnections
                project={project} />,
              // <ProjectDashboardTranslationJobs
              //   project={project} />,
              // <></>,
              <ProjectDashboardConfig
                project={project} />,
              <ProjectActivities
                project={project} />
            ]} />
        ) : (
          <Spinner spinnerType='Chase' />
        )}
      </div>
    </div>
  )
}
