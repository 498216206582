import React, { useEffect, useState } from 'react';
import { GithubRepository } from '../../types/github';
import { ProjectDashboard } from '../../types/project';
import { ModalType } from '../../types/modal';
import { useModal } from '../../hooks/useModal';
import { useTranslation } from '@blendin/sdk-react';
import { APIClient } from '../../services/APIService';
import { UI_ROUTES, API_ROUTES } from '../../helpers/routes';
import { Spinner } from '../Spinner';

interface ModalProps {
  project: ProjectDashboard;
}

export const ConnectGithubRepositoryModal: React.FC<ModalProps> = ({
  project
}) => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [githubRepositories, setGithubRepositories] = useState<GithubRepository[]>([]);
  const [selectedRepository, setSelectedRepository] = useState<GithubRepository|undefined>(undefined);
  const [baseBranchName, setBaseBranchName] = useState<string>('');
  const [prBranchName, setPRBranchName] = useState<string>('');
  const [localesPath, setLocalesPath] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const apiResponse = await APIClient.authenticatedGet(API_ROUTES.UserGithubRepositories);
        if (apiResponse.status === 200) {
          setGithubRepositories(apiResponse.data.repositories);
          setIsLoading(false);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, []);

  const closeModalIfElementClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setActiveModal(ModalType.None);
    }
  }

  const selectRepository = (repository: GithubRepository) => {
    setSelectedRepository(repository);
  }

  const createProjectGithubRepositoryConnection = async () => {
    const apiResponse = await APIClient.authenticatedPost(API_ROUTES.ProjectGithubRepositoryConnectionCreate, {
      project_uuid: project.uuid,
      base_branch_name: baseBranchName || 'main',
      pr_branch_name: prBranchName || 'translations',
      locales_path: localesPath || './',
      github_repository: selectedRepository
    })

    if (apiResponse.status === 201) {
      setActiveModal(ModalType.None);
    } else {
      console.log(`SOMETHING WENT WRONG`);
      console.log(apiResponse);
    }
  }

  return (
    <div
      className='Modal github-repositories'
      onClick={closeModalIfElementClick}>
      <div className='modal-content'>
        <div className='modal-title-container'>
          <h3 className='modal-title'>{t('Select a Repository')}</h3>
          <div className='flex-spacer' />
          <p
            className='modal-close'
            onClick={closeModalIfElementClick}>
            X
          </p>
        </div>
        <div className='modal-body'>
          {isLoading ? (<Spinner spinnerType='Chase' />) : null}
          {selectedRepository ? (
            <>
              <div className='selected-repository'>
                <div
                  key={selectedRepository.id}
                  className='repository'>
                  {selectedRepository.image && <img src={selectedRepository.image} className='repository-image' alt={selectedRepository.name} />}
                  <div>
                    <div className='repository-title'>
                      <strong className='repository-name'>{selectedRepository.name}</strong>
                      <p className='repository-privacy-mode circled-label'>{selectedRepository.is_private ? t('private') : t('public')}</p>
                    </div>
                    <p className='repository-description'>{selectedRepository.description}</p>
                  </div>
                </div>
              </div>
              <div className='form-section'>
                <div className='form-field labeled-input'>
                  <p className='input-label'>{t("Base Branch Name")}</p>
                  <input
                    type="text"
                    placeholder="main"
                    value={baseBranchName}
                    onChange={(e) => setBaseBranchName(e.target.value)} />
                </div>
                <div className='form-field labeled-input'>
                  <p className='input-label'>{t({text: "PR Branch Name", context: "PR is short for Pull Request"})}</p>
                  <input
                    type="text"
                    placeholder="translations"
                    value={prBranchName}
                    onChange={(e) => setPRBranchName(e.target.value)} />
                </div>
                <div className='form-field labeled-input'>
                  <p className='input-label'>{t("Locales Path")}</p>
                  <input
                    type="text"
                    placeholder="./"
                    value={localesPath}
                    onChange={(e) => setLocalesPath(e.target.value)} />
                </div>
              </div>
              <div className='modal-actions'>
                <div
                  className='action-button primary'
                  onClick={createProjectGithubRepositoryConnection}>
                  <p className='action-button-text'>{t('Submit')}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='repositories-container'>
                <div className='repositories'>{githubRepositories.map((repo) => (
                  <div
                    key={repo.id}
                    className='repository'
                    onClick={() => { selectRepository(repo) }}>
                    {repo.image && <img src={repo.image} className='repository-image' alt={repo.name} />}
                    <div>
                      <div className='repository-title'>
                        <strong className='repository-name'>{repo.name}</strong>
                        <p className='repository-privacy-mode circled-label'>{repo.is_private ? t('private') : t('public')}</p>
                      </div>
                      <p className='repository-description'>{repo.description}</p>
                    </div>
                  </div>
                ))}
                </div>
              </div>
              <div className='modal-actions' />
            </>
          )}
        </div>
      </div>
    </div>
  )

}
