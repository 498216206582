import { useState, useEffect } from 'react';
import { getStorageValue, removeStorageValue, setStorageValue } from '../helpers/storage';
import { StorageKeys, StorageValue } from '../types/storage';
import { eventEmitter } from '../services/EventEmitter';

export const useStorage = <T extends StorageKeys>(storageKey: T): [StorageValue[T] | undefined, (value: StorageValue[T] | undefined) => void] => {
  const [value, setValue] = useState<StorageValue[T] | undefined>(undefined);

  useEffect(() => {
    getStorageValue(storageKey).then(fetchedValue => setValue(fetchedValue));
  }, [storageKey]);

  useEffect(() => {
    getStorageValue(storageKey).then(fetchedValue => {
      setValue(fetchedValue);
    });

    const handleStorageChange = (event: { storageKey: StorageKeys, storageValue: any }) => {
      if (event.storageKey === storageKey) {
        setValue(event.storageValue);
      }
    };

    eventEmitter.on('storageChange', handleStorageChange);

    return () => {
      eventEmitter.off('storageChange', handleStorageChange);
    };
  }, [storageKey]);

  const saveValue = (newValue: StorageValue[T] | undefined) => {
    if (newValue !== undefined) {
      setValue(newValue);
      setStorageValue(storageKey, newValue);
    } else {
      setValue(undefined);
      removeStorageValue(storageKey);
    }
  }

  return [value, saveValue];
}
