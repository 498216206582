import { useEffect, useRef, useState } from 'react';
import Prism from 'prismjs';

import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';

import 'prism-sentry';

import copyIcon from '../assets/copy-icon.svg';

export const CodeBlock: React.FC<{ language: string, code: string }> = ({ language, code }) => {
  const codeRef = useRef<HTMLElement>(null);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      <pre>
        <code className={`language-${language}`}>
          {code}
        </code>
      </pre>
      {isCopied ? (<p style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '14px',
        color: '#FFFFFF'
      }}>Copied!</p>) : (
       <img
        src={copyIcon}
        alt="Copy Code"
        onClick={handleCopy}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
          width: '20px',
          height: '20px'
        }} />
      )}
    </div>
  )
}
