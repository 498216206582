import { useEffect, useState } from 'react';
import { useFlashMessage } from '../hooks/useFlashMessage';
import { useLocation } from 'react-router-dom';

export const FlashMessage: React.FC = () => {
  const location = useLocation();
  const { flashMessage, setFlashMessage } = useFlashMessage();

  const [flashNavigationCounter, setFlashNavigationCounter] = useState<number>(0);

  useEffect(() => {
    setFlashNavigationCounter(0);
  }, [flashMessage]);

  useEffect(() => {
    if (flashMessage) {
      const nextFlashNavigationCounter = flashNavigationCounter + 1;
      if (nextFlashNavigationCounter >= 2) {
        setFlashMessage(undefined);
        setFlashNavigationCounter(0);
      } else {
        setFlashNavigationCounter(nextFlashNavigationCounter);
      }
    }
  }, [location.pathname]);

  if (!flashMessage) {
    return null;
  }

  return (
    <div className={`Flash-Message ${flashMessage.type}`}>
      <p className='message-text'>{flashMessage.message}</p>
      <p
        className='close'
        onClick={() => setFlashMessage(undefined)}>X</p>
    </div>
  );
};
