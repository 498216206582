import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation, BlendinContext } from '@blendin/sdk-react';
import { StorageKeys } from '../types/storage';
import { useStorage } from '../hooks/useStorage';
import { UI_ROUTES } from '../helpers/routes';
import { SelectLocale } from '@blendin/sdk-react';
import { BLENDIN_DOCS_URL } from '../constants';

import '@blendin/sdk-react/dist/styles.css';

import chameleonLogo from '../assets/images/chameleon-logo.png';
import hamburger from '../assets/hamburger.svg';

interface HeaderProps {
  userDropdownOpen: boolean;
  setUserDropdownOpen: Dispatch<SetStateAction<boolean>>;
  languageDropdownOpen: boolean;
  setLanguageDropdownOpen: Dispatch<SetStateAction<boolean>>;
  hamburgerDropdownOpen: boolean;
  setHamburgerDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

export const Header: React.FC<HeaderProps> = ({
  userDropdownOpen = false,
  setUserDropdownOpen = () => undefined,
  languageDropdownOpen = false,
  setLanguageDropdownOpen = () => undefined,
  hamburgerDropdownOpen = false,
  setHamburgerDropdownOpen = () => undefined
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useStorage(StorageKeys.CurrentUser);

  const NAVIGATION_LINKS = [
    // {
    //   path: '/',
    //   display: t('navHome')
    // },
    {
      path: BLENDIN_DOCS_URL,
      display: t('Docs'),
      external: true
    },
    // {
    //   path: '/demo',
    //   display: t('Demo')
    // },
    {
      path: '/pricing',
      display: t('Pricing')
    },
    // {
    //   path: '/contact',
    //   display: t('Contact')
    // },
    // {
    //   path: '/faq',
    //   display: t({ text: 'FAQ', context: 'FAQ is an abbreviation for "Frequently Asked Questions"'})
    // }
  ];

  const AUTH_ACTIONS = [
    {
      path: '/login',
      display: t('Log in')
    },
    {
      path: '/signup',
      display: t('Sign up')
    }
  ]

  const toggleUserDropdownOpen = () => {
    setLanguageDropdownOpen(false);
    userDropdownOpen ? setUserDropdownOpen(false) : setUserDropdownOpen(true);
  }

  const toggleHamburgerDropdownOpen = () => {
    setLanguageDropdownOpen(false);
    hamburgerDropdownOpen ? setHamburgerDropdownOpen(false) : setHamburgerDropdownOpen(true);
  }

  const handleDashboardClick = () => {
    setUserDropdownOpen(false);
    if (currentUser!.role === 'translator') {
      navigate(UI_ROUTES.TranslatorDashboard);
    } else {
      navigate(UI_ROUTES.UserDashboard);
    }
  }

  const handleSettingsClick = () => {
    setUserDropdownOpen(false);
    navigate(UI_ROUTES.UserSettings);
  }

  const handleLogoutClick = () => {
    setUserDropdownOpen(false);
    navigate(UI_ROUTES.LogoutCallback);
  }

  return (
    <div className='Header primary'>
      <div className='branding' onClick={() => navigate('/')}>
        <img src={chameleonLogo} />
        <h2>Blendin</h2>
      </div>
      {/* <SelectLocale
        localeDisplay='defaultLanguage'
        displayFlags={true}
        localeDropdownOpen={languageDropdownOpen}
        setLocaleDropdownOpen={setLanguageDropdownOpen} /> */}
      {/* <div className='flex-spacer' /> */}
      <div className='nav'>
        {NAVIGATION_LINKS.map((navigationLink) => {
          const navigationLinkClassName = location.pathname === navigationLink.path ? 'nav-item selected' : 'nav-item';
          if (navigationLink.external) {
            return (
              <a
                key={`${navigationLink.display}`}
                href={navigationLink.path}
                target="_blank"
                className={navigationLinkClassName}>
                {`${navigationLink.display}`}
              </a>
            )
          } else {
            return (
              <Link
                key={`${navigationLink.display}`}
                to={navigationLink.path}
                className={navigationLinkClassName}>
                {`${navigationLink.display}`}
              </Link>
            )
          }
        })}
      </div>
      <div className='flex-spacer' />
      {currentUser ? (
        <div className='current-user-container'>
          <div className='current-user' onClick={toggleUserDropdownOpen}>
            <p className='user-email'>{currentUser.email}</p>
          </div>
          {userDropdownOpen ? (
            <div className={`dropdown-menu user-dropdown-menu`}>
              <div className='dropdown-item' onClick={handleDashboardClick}>
                <p className='dropdown-item-text'>
                  {t('Dashboard')}
                </p>
              </div>
              <div className='dropdown-item' onClick={handleSettingsClick}>
                <p className='dropdown-item-text'>
                  {t('Settings')}
                </p>
              </div>
              <div className='dropdown-item' onClick={handleLogoutClick}>
                <p className='dropdown-item-text'>
                  {t('Log Out')}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className='auth-actions'>
          {AUTH_ACTIONS.map((authLink) => {
            const authLinkClassName = location.pathname === authLink.path ? 'auth-item selected' : 'auth-item';
            if (authLink.path === '/signup') {
              return (
                <div
                  key={'signup'}
                  className='action-button primary signup'
                  onClick={() => navigate(UI_ROUTES.Signup)}>
                  <p className='action-button-text'>{t('Get started free')}</p>
                </div>
              )
            } else {
              return (
                <Link
                  key={authLink.display}
                  to={authLink.path}
                  className={authLinkClassName}>
                  {authLink.display}
                </Link>
              )
            }
          })}
        </div>
      )}
      <div className='hamburger-menu-container'>
        <img
          className='hamburger-menu'
          src={hamburger}
          onClick={toggleHamburgerDropdownOpen} />
        {hamburgerDropdownOpen ? (
          <div className={`dropdown-menu hamburger-dropdown-menu`}>
            {NAVIGATION_LINKS.map((navigationLink) => {
              if (navigationLink.external) {
                return (
                  <div className='dropdown-item' key={`${navigationLink.display}`}>
                    <a
                      href={navigationLink.path}
                      target="_blank"
                      className={`dropdown-item-text`}>
                      {`${navigationLink.display}`}
                    </a>
                  </div>
                )
              } else {
                return (
                  <div className='dropdown-item' key={`${navigationLink.display}`}>
                    <Link
                      key={`${navigationLink.display}`}
                      to={navigationLink.path}
                      className={`dropdown-item-text`}>
                      {`${navigationLink.display}`}
                    </Link>
                  </div>
                )
              }
            })}
            {currentUser ? (
              <>
                <div className='dropdown-item' onClick={handleDashboardClick}>
                  <p className='dropdown-item-text'>
                    {t('Dashboard')}
                  </p>
                </div>
                <div className='dropdown-item' onClick={handleSettingsClick}>
                  <p className='dropdown-item-text'>
                    {t('Settings')}
                  </p>
                </div>
                <div className='dropdown-item' onClick={handleLogoutClick}>
                  <p className='dropdown-item-text'>
                    {t('Log Out')}
                  </p>
                </div>
              </>
            ) : (
              <>
                {AUTH_ACTIONS.map((authLink) => {
                  return (
                    <div className='dropdown-item' key={authLink.display}>
                      <Link
                        to={authLink.path}
                        className={`dropdown-item-text`}>
                        {authLink.display}
                      </Link>
                    </div>
                  )
                })}
              </>
            )}
        </div>
        ) : null}
      </div>
    </div>
  );

}
