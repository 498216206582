import { useState, cloneElement } from 'react';

type SubNavigationProps = {
  navItems: string[];
  navComponents: any[];
}

export const SubNavigation: React.FC<SubNavigationProps> = ({ navItems, navComponents }) => {

  const [activeNavIndex, setActiveNavIndex] = useState(0);

  return (
    <div className={`Sub-Navigation`}>
      <div className='nav-items'>
        {navItems.map((navItem, index) => {
          const navItemClassName = index === activeNavIndex ? 'nav-item active' : 'nav-item'
          return (
            <div
              key={index}
              className={navItemClassName}
              onClick={() => setActiveNavIndex(index)}>
              <p>{navItem}</p>
            </div>
          )
        })}
      </div>
      <div className='nav-components'>
        {navComponents.map((navComponent, index) => {
          if (index === activeNavIndex) {
            return cloneElement(navComponent, { key: index });
          } else {
            return null;
          }
        })}
      </div>
    </div>
  )

}
