import React, { useState, useEffect } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Locale } from '../types/locale';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { APIClient } from '../services/APIService';
import { Checkbox } from 'pretty-checkbox-react';
import { useFlashMessage } from '../hooks/useFlashMessage';

export const ProjectTranslationJobCreateScreen: React.FC = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();

  const [translationJobName, setTranslationJobName] = useState<string>('');
  const [sourceText, setSourceText] = useState<string>('');
  const [locales, setLocales] = useState<Locale[]>([]);
  const [selectedSourceLocale, setSelectedSourceLocale] = useState<string>('en');
  const [selectedTargetLocales, setSelectedTargetLocales] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const localesResponse = await APIClient.get(API_ROUTES.LocalesIndex, {})
      if (localesResponse.status === 200) {
        setLocales(localesResponse.data.locales);
      }
    })();
  }, []);

  const handleTargetLocaleSelect = (nextValue: boolean, iso: string) => {
    if (nextValue) {
      setSelectedTargetLocales([...selectedTargetLocales, iso]);
    } else {
      setSelectedTargetLocales(selectedTargetLocales.filter(value => value !== iso));
    }
  }

  const createTranslationJob = async () => {
    const translationJobResponse = await APIClient.authenticatedPost(API_ROUTES.TranslationJobsCreate, {
      project_uuid: uuid!,
      source_locale_iso: selectedSourceLocale,
      source_text: sourceText,
      name: translationJobName,
      target_locale_isos: selectedTargetLocales
    })

    if (translationJobResponse.status === 201) {
      setFlashMessage({ message: t('Successfully created translation job'), type: 'success' })
      navigate(UI_ROUTES.ProjectDashboard.replace(':uuid', uuid!));
    }
  }

  return (
    <div className='project-translation-job-create-screen'>
      <div className='content-container'>
        <h2 className='page-title'>{t('Create Translation Job')}</h2>
        <div className='form-section'>
          <div className='form-field labeled-input translation-job-name'>
            <p className='input-label'>{t('Translation Job Name')}</p>
            <input
              type='text'
              placeholder={t('A label for your translation job')}
              value={translationJobName}
              onChange={(e) => setTranslationJobName(e.target.value)} />
          </div>
          <div className='form-field labeled-input source-text'>
            <p className='input-label'>{t('Source Text')}</p>
            <textarea
              value={sourceText}
              onChange={(e) => setSourceText(e.target.value)} />
          </div>
          <div className='form-field labeled-input source-locale'>
            <p className='input-label'>{t('Source language')}</p>
            <select
              className='source-locale-select'
              value={selectedSourceLocale}
              onChange={(e) => setSelectedSourceLocale(e.target.value)}>
              {locales.map((locale, index) => (
                <option key={index} value={locale.iso}>
                  {locale.name}
                </option>
              ))}
            </select>
          </div>
          <div className='form-field labeled-input target-locales'>
            <p className='input-label'>{t('Target languages')}</p>
            <div className='target-locales-select'>
              <div className='select-locales'>
                {locales.map((locale, index) => (
                  <div
                    key={index}
                    className='target-locale-select'
                    onClick={() => {
                      const nextValue = !selectedTargetLocales.includes(locale.iso);
                      handleTargetLocaleSelect(nextValue, locale.iso);
                    }}>
                    <Checkbox
                      readOnly={true}
                      className='checkbox-wrapper'
                      checked={selectedTargetLocales.includes(locale.iso)} />
                    <p className=''>{locale.name}</p>
                  </div>
                ))}
              </div>
              <div className='selected-locales'>
                {selectedTargetLocales.map((iso, index) => (
                  <p
                    key={index}
                    className='selected-locale'>{locales.find((locale) => locale.iso === iso)!.name}</p>
                ))}
              </div>
            </div>
          </div>
          <div className='action-buttons'>
            <div className='flex-spacer' />
            <div
              className='action-button primary'
              onClick={createTranslationJob}>
              <p className='action-button-text'>{t('Create Translation Job')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
