import React, { useState, useEffect } from 'react';
import { paginate } from '../helpers/enumerable';

interface DataTableRow {
  items: any[],
  onClickRow?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  onDoubleClickRow?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

interface IDataTableProps {
  dataRows: DataTableRow[];
  className?: string;
  headerRow?: string[];
  itemsPerPage?: number;
}

interface DataItemAction {
  text: string;
  onClick: () => void;
}

export const DataTable: React.FC<IDataTableProps> = ({
  dataRows,
  className,
  headerRow,
  itemsPerPage,
}) => {
  const columns: number = headerRow ? headerRow.length : dataRows[0].items.length;
  const shouldShowControls: boolean = !!(itemsPerPage && itemsPerPage < dataRows.length);
  const itemsPerPageValue = itemsPerPage ?? dataRows.length;

  const [activePageIndex, setActivePageIndex] = useState<number>(0);
  const [activeDataRows, setActiveDataRows] = useState<DataTableRow[]>(paginate(dataRows, itemsPerPageValue, 0))

  useEffect(() => {
    setActiveDataRows(paginate(dataRows, itemsPerPageValue, activePageIndex));
  }, [activePageIndex])

  const incrementPage = () => {
    setActivePageIndex(Math.min(activePageIndex + 1, Math.floor(dataRows.length / itemsPerPageValue)))
  }

  const decrementPage = () => {
    setActivePageIndex(Math.max(0, activePageIndex - 1));
  }

  const displayActivePageSummary = (): string => {
    const startIndex = activePageIndex * itemsPerPageValue;
    const endIndex = startIndex + itemsPerPageValue;
    return `${startIndex + 1} - ${Math.min(endIndex, dataRows.length)} out of ${dataRows.length}`;
  }

  return (
    <div className={`data-table columns-${columns} ${className}`}>
      {headerRow ? (
        <div className='table-header'>
          {headerRow.map((headerText, headerIndex) => (
            <p className='string' key={headerIndex}>{headerText}</p>
          ))}
        </div>
      ) : (<></>)}
      <div className='table-body'>
        {activeDataRows.map((dataRow, rowIndex) => (
          <div
            key={rowIndex}
            className={'table-row'}
            onClick={dataRow.onClickRow}
            onDoubleClick={dataRow.onDoubleClickRow}>
            {dataRow.items.map((dataItem, itemIndex) => (
              <React.Fragment  key={`${rowIndex}-${itemIndex}`}>
                {dataItem && typeof dataItem === 'string' ? (
                  <p className='string'>{dataItem}</p>
                ) : null}
                {dataItem && typeof dataItem === 'object' ? (
                  <>
                    {dataItem.actions ? (
                      <div className='action-buttons'>
                        {dataItem.actions.map((action: DataItemAction, actionIndex: number) => (
                          <div
                            key={`${rowIndex}-${itemIndex}-${actionIndex}`}
                            className='action-button secondary'
                            onClick={action.onClick}>
                            <p className='action-button-text'>{action.text}</p>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
      {shouldShowControls ? (
        <div className='table-controls'>
          <p className='table-item-info'>{displayActivePageSummary()}</p>
          <div className='controls'>
            <div
              className='control control-back'
              onClick={decrementPage}>
              <p>{`<`}</p>
            </div>
            <div
              className='control control-forward'
              onClick={incrementPage}>
              <p>{`>`}</p>
            </div>
          </div>
        </div>
      ) : (<></>)}
    </div>
  )
}
