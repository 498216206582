import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageKeys } from '../types/storage';
import { useStorage } from '../hooks/useStorage';
import { UI_ROUTES } from '../helpers/routes';

import posthog from 'posthog-js';

export const UserSignupCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userSession, setUserSession] = useStorage(StorageKeys.UserSession);
  const [currentUser, setCurrentUser] = useStorage(StorageKeys.CurrentUser);

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');
      const userUUID = query.get('user_uuid');
      const userEmail = query.get('user_email');
      const userRole = query.get('user_role');
      if (token) {
        posthog.identify(userUUID!, {
          email: userEmail!,
          role: userRole!
        });
        setUserSession(token);
        setCurrentUser({ uuid: userUUID!, email: userEmail!, role: userRole! as 'user' | 'translator' | 'admin' });
        if (userRole === 'translator') {
          navigate(UI_ROUTES.TranslatorDashboard);
        } else {
          navigate(UI_ROUTES.UserDashboard);
        }
      } else {
        navigate(UI_ROUTES.Error);
      }
    })()
  }, [location])

  return null;
}
