import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '../components/Spinner';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { APIClient } from '../services/APIService';
import { useNavigate } from 'react-router-dom';
import { SubNavigation } from '../components/SubNavigation';
import { DataTable } from '../components/DataTable';
import { TranslationString } from '../types/translation_string';
import { useTranslation } from '@blendin/sdk-react';
import { useModal } from '../hooks/useModal';
import { ModalType } from '../types/modal';

interface TranslationsIndexProps {
  unconfirmedTranslations: TranslationString[];
}

const TranslatorConfirmTranslationsIndex: React.FC<TranslationsIndexProps> = ({ unconfirmedTranslations }) => {
  const { t } = useTranslation();
  const { setActiveModal } = useModal();

  return (
    <div>
      <DataTable
        className='unconfirmed-translations-table'
        headerRow={[t('Source string'), t('Translation string'), t('Actions')]}
        itemsPerPage={25}
        dataRows={
          unconfirmedTranslations.map((translationString, index) => ({
            items: [
              translationString.source_string_value,
              translationString.translation_string_value,
              {actions: [
                {
                  text: t({text: 'Confirm', context: 'command'}),
                  onClick: async () => {
                   await APIClient.authenticatedPost(API_ROUTES.TranslationStringConfirmTranslation, {
                    translation_string_uuid: translationString.uuid
                   });
                  }
                },
                {
                  text: t({text: 'Edit', context: 'command'}),
                  onClick: () => {
                    setActiveModal(ModalType.EditTranslation, {
                      translationString
                    })
                  }
                }
              ]}
            ]
          }))
        } />

    </div>
  )
}

const TranslatorConfirmTranslationsSwipe: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>SWIPE</p>
    </div>
  )
}

export const TranslatorConfirmTranslationsScreen: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { iso } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unconfirmedTranslations, setUnconfirmedTranslations] = useState<TranslationString[]>([]);

  useEffect(() => {
    (async () => {
      const userResponse = await APIClient.authenticatedGet(API_ROUTES.TranslatorConfirmTranslations.replace(':iso', iso!));

      if (userResponse.status === 200) {
        setUnconfirmedTranslations(userResponse.data.locale.unconfirmed_translations);
        setIsLoading(false);
      } else if (userResponse.status === 401) {
        navigate(UI_ROUTES.Login);
      }
    })();
  }, []);

  return (
    <div className='translator-confirm-translations-screen'>
      <div className='content-container-wide'>
        {isLoading ? (
          <Spinner spinnerType='Chase' />
        ) : (
          <>
            <div className='locale-name-container'>
              <h2 className='locale-name'>{'Arabic'}</h2>
              <p className='locale-iso circled-label'>{'ar'}</p>
            </div>
            <SubNavigation
              navItems={[
                t('Index'),
                t('Swipe')
              ]}
              navComponents={[
                <TranslatorConfirmTranslationsIndex
                  unconfirmedTranslations={unconfirmedTranslations} />,
                <TranslatorConfirmTranslationsSwipe />
              ]} />
          </>
        )}
      </div>
    </div>
  )

}
