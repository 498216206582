import { useTranslation } from '@blendin/sdk-react';
import { Link } from 'react-router-dom';

import logoTwitter from '../assets/logo-twitter.png';
import logoFacebook from '../assets/logo-facebook.png';
import logoInstagram from '../assets/logo-instagram.png';
import logoLinkedin from '../assets/logo-linkedin.png';
import { UI_ROUTES } from '../helpers/routes';
import { BLENDIN_X_URL, BLENDIN_FACEBOOK_URL, BLENDIN_LINKEDIN_URL, BLENDIN_DOCS_URL } from '../constants';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='Footer'>
      <div className='branding'>
        <h4 className='blendin'>Blendin</h4>
        <div className='socials-container'>
          <a href={BLENDIN_X_URL} target='_blank'>
            <img className='social-logo twitter' src={logoTwitter} />
          </a>
          <a href={BLENDIN_FACEBOOK_URL} target='_blank'>
            <img className='social-logo facebook' src={logoFacebook} />
          </a>
          <a href={BLENDIN_LINKEDIN_URL} target='_blank'>
            <img className='social-logo linkedin' src={logoLinkedin} />
          </a>
        </div>
      </div>
      <div className='section-containers mobile'>
        <div className='section-container'>
          <a href={BLENDIN_DOCS_URL} target="_blank" className='section-item'>Docs</a>
          <Link to={UI_ROUTES.Home} className='section-item'>Contact us</Link>
          <Link to={UI_ROUTES.Home} className='section-item'>Careers</Link>
        </div>
        <div className='flex-spacer' />
        <div className='section-container'>
          <Link to={UI_ROUTES.Home} className='section-item'>Features</Link>
          <Link to={UI_ROUTES.Pricing} className='section-item'>Pricing</Link>
          <Link to={UI_ROUTES.Home} className='section-item'>Terms & Conditions</Link>
        </div>
      </div>
      <div className='section-container company desktop'>
        <h4 className='section-title'>Company</h4>
        <Link to={UI_ROUTES.Home} className='section-item'>About us</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Contact us</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Careers</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Press</Link>
      </div>
      <div className='section-container product desktop'>
        <h4 className='section-title'>Product</h4>
        <Link to={UI_ROUTES.Home} className='section-item'>Features</Link>
        <Link to={UI_ROUTES.Pricing} className='section-item'>Pricing</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>News</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Help desk</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Support</Link>
      </div>
      <div className='section-container legal desktop'>
        <h4 className='section-title'>Legal</h4>
        <Link to={UI_ROUTES.Home} className='section-item'>Privacy Policy</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Terms & Conditions</Link>
        <Link to={UI_ROUTES.Home} className='section-item'>Return Policy</Link>
      </div>
    </div>
  )

}
